import React, { Component ,useState} from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form, Row, Col , Navbar , Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import Header from './Header/Header';
import s21 from '../Images/s21.png'
import s22 from '../Images/s22.png'
import s23 from '../Images/s23.png'
import s24 from '../Images/s24.png'
import s25 from '../Images/s25.png'
import p21 from '../Images/P21.png'
import p22 from '../Images/p22.png'
import p23 from '../Images/p23.png'
import p221 from '../Images/p221.png'
import p222 from '../Images/p222.png'
import p223 from '../Images/p223.png'
import p224 from '../Images/p224.png'
import graph from '../Images/graph.png'
import p51 from '../Images/p51.png'
import p52 from '../Images/p52.png'
import p53 from '../Images/p53.png'
import p54 from '../Images/p54.png'
import p55 from '../Images/p55.png'
import pl1 from '../Images/pl1.png'
import pl2 from '../Images/pl2.png'
import pl3 from '../Images/pl3.png'
import pl4 from '../Images/pl4.png'
import pl6 from '../Images/pl6.png'
import pl7 from '../Images/pl7.png'
import pl8 from '../Images/pl8.png'

import homeImg from '../Images/home page.png'
import Footer from './Footer';

export default class Home extends Component {
    state = {
        isHovered: false,
      };
      handleMouseEnter = () => {
        this.setState({ isHovered: true });
      };
    
      handleMouseLeave = () => {
        this.setState({ isHovered: false });
      };
    
    render() {
   
        const { isHovered } = this.state;
        return (
          <>
          <section className="DivBg" >
          <Header/>
<br/>
<br/>
<div className="row m-0">
    <div className="col-md-7 col-sm-12" style={{marginTop:'5%'}}>
    <h1 className="text-bold" style={{fontWeight:'700'}}>One-stop platform for<br/>
all things NFTs 
</h1>
<p style={{marginTop:'20px'}}>Trade, Stake and Loan NFTs securely on SharkWeb NFT</p>
<a href="/signin" style={{cursor:"pointer"}}>
            <button className="headerGrad" style={{cursor:"pointer",backgroundColor:'#ae46fe ',color:"white",minWidth:'9rem',fontWeight:'600',fontSize:'13px',border:'1px solid #ae46fe',marginTop:'15px',marginRight:'0px',minHeight:'5vh',marginRight:'10px',borderRadius:".5rem"}}>            
            <span style={{fontSize:"15px"}}>Learn More</span></button>
            </a>
    </div>
    {/* <div className="col-md-3 col-sm-12 ">
    <div className="card-purple" style={{backgroundColor:"#eee7ff"}}>
   <img src={s21} style={{height:'25vh',float:'center'}}/>
   <br/> 
   <div style={{paddingTop:'5%'}}>
   <span style={{fontSize:'11px',marginBottom:'0px',fontWeight:'700'}}>"Hamlet Contemplates</span>
   <button className="buds" style={{float:'right',minHeight:'',fontSize:''}}>BUSD</button>
   </div>

   <div className="row  " style={{marginTop:'3.5%'}}>
    <div className="col-2 p-0">
<img src={s25} style={{height:'4.2vh'}}/>
    </div>
    <div className="col-5">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Creator</p>
  
        <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>John Doe</p>
        </div>
        <div className="col-5 text-right">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Current Bid</p>
  
  <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>200 AIFI</p> 
        </div>
   </div>
   </div>
        </div>
        <div className="col-md-3 col-sm-12 " >
        <div className="card-purple" style={{backgroundColor:"#eee7ff"}}>
   <img src={s21} style={{height:'25vh',float:'center'}}/>

  


   </div>
        </div> */}
        <div className="col-md-5 col-sm-12 text-right">
            <img className="H24_5" src={homeImg} style={{height:'35vh'}}/>
        </div>
</div>

</section>
          <div className="LandingBg">



<div> 

    <span style={{fontSize:'25px',fontWeight:'600',marginLeft:'15px'}}>Live Auction</span>
    <a href="#" style={{cursor:"pointer",float:'right'}}>
            <button className="headerGrad" style={{cursor:"pointer",backgroundColor:'#ae46fe ',color:"white",minWidth:'8rem',fontWeight:'600',fontSize:'13px',border:'1px solid #ae46fe',marginLeft:'15px',marginRight:'0px',minHeight:'5vh',marginTop:'',marginRight:'10px',borderRadius:".5rem"}}>            
            <span style={{fontSize:"15px"}}>View More</span></button>
            </a>
            <br/>     <br/>
<div className="row m-0 p-0">
    <div className="col-md-6 col-lg-3 col-sm-12">
   <div className="card-purple">
   <img src={s21} style={{height:'25vh',float:'center'}}/>
   <br/> 
   <div style={{paddingTop:'5%'}}>
   <span style={{fontSize:'11px',marginBottom:'0px',fontWeight:'700'}}>"Hamlet Contemplates</span>
   <button className="buds" style={{float:'right',minHeight:'',fontSize:''}}>BUSD</button>
   </div>

   <div className="row p-0 ml-0 mr-0 " style={{marginTop:'3.5%'}}>
    <div className="col-2 p-0">
<img src={s25} style={{height:'4.2vh'}}/>
    </div>
    <div className="col-5">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Creator</p>
  
        <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>John Doe</p>
        </div>
        <div className="col-5 text-right">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Current Bid</p>
  
  <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>200 AIFI</p> 
        </div>
   </div>
   </div>
    </div>
    <div className="col-md-6 col-lg-3 col-sm-12">
    <div className="card-purple MarginT30">
   <img src={s22} style={{height:'25vh',float:'center'}}/>
   <br/> 
   <div style={{paddingTop:'5%'}}>
   <span style={{fontSize:'11px',marginBottom:'0px',fontWeight:'700'}}>"Hamlet Contemplates</span>
   <button className="buds" style={{float:'right',minHeight:'',fontSize:''}}>BUSD</button>
   </div>

   <div className="row p-0 ml-0 mr-0 " style={{marginTop:'3.5%'}}>
    <div className="col-2 p-0">
<img src={s25} style={{height:'4.2vh'}}/>
    </div>
    <div className="col-5">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Creator</p>
  
        <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>John Doe</p>
        </div>
        <div className="col-5 text-right">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Current Bid</p>
  
  <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>200 AIFI</p> 
        </div>
   </div>
   </div>
        </div>
        <div className="col-md-6 col-lg-3 col-sm-12 ">
        <div className="card-purple MarginT30 MarginTt30">
   <img src={s23} style={{height:'25vh',float:'center'}}/>
   <br/> 
   <div style={{paddingTop:'5%'}}>
   <span style={{fontSize:'11px',marginBottom:'0px',fontWeight:'700'}}>"Hamlet Contemplates</span>
   <button className="buds" style={{float:'right',minHeight:'',fontSize:''}}>BUSD</button>
   </div>

   <div className="row p-0 ml-0 mr-0 " style={{marginTop:'3.5%'}}>
    <div className="col-2 p-0">
<img src={s25} style={{height:'4.2vh'}}/>
    </div>
    <div className="col-5">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Creator</p>
  
        <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>John Doe</p>
        </div>
        <div className="col-5 text-right">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Current Bid</p>
  
  <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>200 AIFI</p> 
        </div>
   </div>
   </div> 
        </div>
        <div className="col-md-6 col-lg-3 col-sm-12">
        <div className="card-purple MarginT30 MarginTt30">
   <img src={s24} style={{height:'25vh',float:'center'}}/>
   <br/> 
   <div style={{paddingTop:'5%'}}>
   <span style={{fontSize:'11px',marginBottom:'0px',fontWeight:'700'}}>"Hamlet Contemplates</span>
   <button className="buds" style={{float:'right',minHeight:'',fontSize:''}}>BUSD</button>
   </div>

   <div className="row p-0 ml-0 mr-0 " style={{marginTop:'3.5%'}}>
    <div className="col-2 p-0">
<img src={s25} style={{height:'4.2vh'}}/>
    </div>
    <div className="col-5">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Creator</p>
  
        <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>John Doe</p>
        </div>
        <div className="col-5 text-right">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Current Bid</p>
  
  <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>200 AIFI</p> 
        </div>
   </div>
   </div>
        </div>
</div> 
</div>

<br/><br/>
<div>

    <span style={{fontSize:'25px',fontWeight:'600',marginLeft:'15px'}}>Featured Artist</span>
    <a href="#" style={{cursor:"pointer",float:'right'}}>
            <button className="headerGrad" style={{cursor:"pointer",backgroundColor:'#ae46fe ',color:"white",minWidth:'8rem',fontWeight:'600',fontSize:'13px',border:'1px solid #ae46fe',marginLeft:'15px',marginRight:'0px',minHeight:'5vh',marginTop:'',marginRight:'15px',borderRadius:".5rem"}}>            
            <span style={{fontSize:"15px"}}>View More</span></button>
            </a>
            <br/>   <br/>
            <div
            className="MobileNone"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginLeft:'',
        marginRight:'',
        paddingLeft:'.5%',
        paddingRight:'.5%'
      }}
    >
      <div className="p-2" style={{ flex: 1, backgroundColor: '', height: '' }}>
      <div className="card-purple" style={{backgroundColor:"#eee7ff"}}>
        <img className="H15" src={p51} style={{height:'22vh',width:'100%'}}/>
        <center>
        <p style={{fontSize:'14px',fontWeight:'700',marginTop:'15px',marginBottom:'0px'}}>CryptoPunks</p>
        <p style={{fontSize:'12px',fontWeight:'700',marginTop:'',marginBottom:'0px'}}>818.7K</p>
        </center>
      </div>
      </div>
      <div className="p-2" style={{ flex: 1, backgroundColor: '', height: '' }}>
      <div className="card-purple" style={{backgroundColor:"#eee7ff"}}>
        <img className="H15" src={p52} style={{height:'22vh',width:'100%'}}/>
        <center>
        <p style={{fontSize:'14px',fontWeight:'700',marginTop:'15px',marginBottom:'0px'}}>Doodles</p>
        <p style={{fontSize:'12px',fontWeight:'700',marginTop:'',marginBottom:'0px'}}>82.7K</p>
        </center>
      </div>
      </div>
      <div className="p-2" style={{ flex: 1, backgroundColor: '', height: '' }}>
      <div className="card-purple" style={{backgroundColor:"#eee7ff"}}>
        <img className="H15" src={p53} style={{height:'22vh',width:'100%'}}/>
        <center>
        <p style={{fontSize:'14px',fontWeight:'700',marginTop:'15px',marginBottom:'0px'}}>Bored Ape Yatch</p>
        <p style={{fontSize:'12px',fontWeight:'700',marginTop:'',marginBottom:'0px'}}>511.7K</p>
        </center>
      </div>
      </div>
      <div className="p-2" style={{ flex: 1, backgroundColor: '', height: '' }}>
      <div className="card-purple" style={{backgroundColor:"#eee7ff"}}>
        <img className="H15" src={p54} style={{height:'22vh',width:'100%'}}/>
        <center>
        <p style={{fontSize:'14px',fontWeight:'700',marginTop:'15px',marginBottom:'0px'}}>Lorem ipsum</p>
        <p style={{fontSize:'12px',fontWeight:'700',marginTop:'',marginBottom:'0px'}}>63.7K</p>
        </center>
      </div>
      </div>
      <div className="p-2" style={{ flex: 1, backgroundColor: '', height: '' }}>
      <div className="card-purple" style={{backgroundColor:"#eee7ff"}}>
        <img className="H15" src={p55} style={{height:'22vh',width:'100%'}}/>
        <center>
        <p style={{fontSize:'14px',fontWeight:'700',marginTop:'15px',marginBottom:'0px'}}>Dragonnn</p>
        <p style={{fontSize:'12px',fontWeight:'700',marginTop:'',marginBottom:'0px'}}>818.7K</p>
        </center>
      </div>
      </div>
    </div>

    <div
            className="MobileOnly">
                <div className="p-3">
                <div className="card-purple" style={{backgroundColor:"#eee7ff"}}>
        <img className="H15" src={p51} style={{height:'22vh',width:'100%'}}/>
        <center>
        <p style={{fontSize:'14px',fontWeight:'700',marginTop:'15px',marginBottom:'0px'}}>CryptoPunks</p>
        <p style={{fontSize:'12px',fontWeight:'700',marginTop:'',marginBottom:'0px'}}>818.7K</p>
        </center>
      </div>    
                </div>
                <div className="p-3">
                <div className="card-purple" style={{backgroundColor:"#eee7ff"}}>
        <img src={p52} style={{height:'22vh',width:'100%'}}/>
        <center>
        <p style={{fontSize:'14px',fontWeight:'700',marginTop:'15px',marginBottom:'0px'}}>Doodles</p>
        <p style={{fontSize:'12px',fontWeight:'700',marginTop:'',marginBottom:'0px'}}>82.7K</p>
        </center>
      </div>
                </div>
                <div className="p-3">
                <div className="card-purple" style={{backgroundColor:"#eee7ff"}}>
        <img src={p53} style={{height:'22vh',width:'100%'}}/>
        <center>
        <p style={{fontSize:'14px',fontWeight:'700',marginTop:'15px',marginBottom:'0px'}}>Bored Ape Yatch</p>
        <p style={{fontSize:'12px',fontWeight:'700',marginTop:'',marginBottom:'0px'}}>511.7K</p>
        </center>
      </div>        
                    </div>
                    <div className="p-3">
                    <div className="card-purple" style={{backgroundColor:"#eee7ff"}}>
        <img src={p54} style={{height:'22vh',width:'100%'}}/>
        <center>
        <p style={{fontSize:'14px',fontWeight:'700',marginTop:'15px',marginBottom:'0px'}}>Lorem ipsum</p>
        <p style={{fontSize:'12px',fontWeight:'700',marginTop:'',marginBottom:'0px'}}>63.7K</p>
        </center>
      </div>         
                    </div>
                    <div className="p-3">
                    <div className="card-purple" style={{backgroundColor:"#eee7ff"}}>
        <img src={p55} style={{height:'22vh',width:'100%'}}/>
        <center>
        <p style={{fontSize:'14px',fontWeight:'700',marginTop:'15px',marginBottom:'0px'}}>Dragonnn</p>
        <p style={{fontSize:'12px',fontWeight:'700',marginTop:'',marginBottom:'0px'}}>818.7K</p>
        </center>
      </div>       
                    </div>
                   
            </div>

            </div>
            <br/> <br/>
<div>

    <span style={{fontSize:'25px',fontWeight:'600',marginLeft:'15px'}}>Hot NFTs</span>
  
            <br/>     <br/>
<div className="row m-0 p-0 ">
    <div className="col-md-6 col-lg-3 col-sm-12">
   <div className="card-purple" style={{ position: 'relative' }}>
   {/* <img src={pl1}
            style={{ height: '25vh', filter: isHovered ? 'blur(3px)' : 'none' }}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave} />
   {isHovered && (
          <button className="button" type='button' style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)' ,backgroundColor:'#aa3efe',minWidth:'7rem',border:'none',borderRadius:'.25rem',color:'white',minHeight:'5vh',cursor:'pointer'}}>
            View
          </button>
        )} */}
     
        <img src={pl1} style={{height:'25vh',width:'100%'}}/>
 
          
   <br/> 
   <div style={{paddingTop:'5%'}}>
   <span style={{fontSize:'11px',marginBottom:'0px',fontWeight:'700'}}>"Hamlet Contemplates</span>
   <button className="buds" style={{float:'right',minHeight:'',fontSize:''}}>BUSD</button>
   </div>

   <div className="row p-0 ml-0 mr-0 " style={{marginTop:'3.5%'}}>
    <div className="col-2 p-0">
<img src={s25} style={{height:'4.2vh'}}/>
    </div>
    <div className="col-5">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Creator</p>
  
        <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>John Doe</p>
        </div>
        <div className="col-5 text-right">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Current Bid</p>
  
  <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>200 AIFI</p> 
        </div>
   </div>
   </div>
    </div>
    <div className="col-md-6 col-lg-3 col-sm-12">
    <div className="card-purple MarginT30">

   <img src={pl2} style={{height:'25vh',float:'center',width:'100%'}}/>

   <br/> 
   <div style={{paddingTop:'5%'}}>
   <span style={{fontSize:'11px',marginBottom:'0px',fontWeight:'700'}}>"Hamlet Contemplates</span>
   <button className="buds" style={{float:'right',minHeight:'',fontSize:''}}>BUSD</button>
   </div>

   <div className="row p-0 ml-0 mr-0 " style={{marginTop:'3.5%'}}>
    <div className="col-2 p-0">
<img src={s25} style={{height:'4.2vh'}}/>
    </div>
    <div className="col-5">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Creator</p>
  
        <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>John Doe</p>
        </div>
        <div className="col-5 text-right">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Current Bid</p>
  
  <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>200 AIFI</p> 
        </div>
   </div>
   </div>
        </div>
        <div className="col-md-6 col-lg-3 col-sm-12 MarginTt30">
        <div className="card-purple MarginT30">
        <Link to="/nft-description">
   <img src={pl3} style={{height:'25vh',float:'center',width:'100%'}}/>
   </Link>
   <br/> 
   <div style={{paddingTop:'5%'}}>
   <span style={{fontSize:'11px',marginBottom:'0px',fontWeight:'700'}}>"Hamlet Contemplates</span>
   <button className="buds" style={{float:'right',minHeight:'',fontSize:''}}>BUSD</button>
   </div>

   <div className="row p-0 ml-0 mr-0 " style={{marginTop:'3.5%'}}>
    <div className="col-2 p-0">
<img src={s25} style={{height:'4.2vh'}}/>
    </div>
    <div className="col-5">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Creator</p>
  
        <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>John Doe</p>
        </div>
        <div className="col-5 text-right">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Current Bid</p>
  
  <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>200 AIFI</p> 
        </div>
   </div>
   </div> 
        </div>
        <div className="col-md-6 col-lg-3 col-sm-12 MarginTt30">
        <div className="card-purple MarginT30">
   <img src={pl4} style={{height:'25vh',float:'center',width:'100%'}}/>
   <br/> 
   <div style={{paddingTop:'5%'}}>
   <span style={{fontSize:'11px',marginBottom:'0px',fontWeight:'700'}}>"Hamlet Contemplates</span>
   <button className="buds" style={{float:'right',minHeight:'',fontSize:''}}>BUSD</button>
   </div>

   <div className="row p-0 ml-0 mr-0 " style={{marginTop:'3.5%'}}>
    <div className="col-2 p-0">
<img src={s25} style={{height:'4.2vh'}}/>
    </div>
    <div className="col-5">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Creator</p>
  
        <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>John Doe</p>
        </div>
        <div className="col-5 text-right">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Current Bid</p>
  
  <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>200 AIFI</p> 
        </div>
   </div>
   </div>
        </div>
</div> 


<br/>
<div className="row m-0 p-0">
    <div className="col-md-6 col-lg-3 col-sm-12">
   <div className="card-purple " style={{ position: 'relative' }}>
   {/* <img src={pl1}
            style={{ height: '25vh', filter: isHovered ? 'blur(3px)' : 'none' }}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave} />
   {isHovered && (
          <button className="button" type='button' style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)' ,backgroundColor:'#aa3efe',minWidth:'7rem',border:'none',borderRadius:'.25rem',color:'white',minHeight:'5vh',cursor:'pointer'}}>
            View
          </button>
        )} */}
        <img className="W100" src={pl1} style={{height:'25vh',width:'100%'}}/>
   <br/> 
   <div style={{paddingTop:'5%'}}>
   <span style={{fontSize:'11px',marginBottom:'0px',fontWeight:'700'}}>"Hamlet Contemplates</span>
   <button className="buds" style={{float:'right',minHeight:'',fontSize:''}}>BUSD</button>
   </div>

   <div className="row p-0 ml-0 mr-0 " style={{marginTop:'3.5%'}}>
    <div className="col-2 p-0">
<img src={s25} style={{height:'4.2vh'}}/>
    </div>
    <div className="col-5">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Creator</p>
  
        <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>John Doe</p>
        </div>
        <div className="col-5 text-right">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Current Bid</p>
  
  <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>200 AIFI</p> 
        </div>
   </div>
   </div>
    </div>
    <div className="col-md-6 col-lg-3 col-sm-12">
    <div className="card-purple MarginT30">
   <img src={pl6} style={{height:'25vh',float:'center',width:'100%'}}/>
   <br/> 
   <div style={{paddingTop:'5%'}}>
   <span style={{fontSize:'11px',marginBottom:'0px',fontWeight:'700'}}>"Hamlet Contemplates</span>
   <button className="buds" style={{float:'right',minHeight:'',fontSize:''}}>BUSD</button>
   </div>

   <div className="row p-0 ml-0 mr-0 " style={{marginTop:'3.5%'}}>
    <div className="col-2 p-0">
<img src={s25} style={{height:'4.2vh'}}/>
    </div>
    <div className="col-5">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Creator</p>
  
        <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>John Doe</p>
        </div>
        <div className="col-5 text-right">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Current Bid</p>
  
  <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>200 AIFI</p> 
        </div>
   </div>
   </div>
        </div>
        <div className="col-md-6 col-lg-3 col-sm-12 MarginTt30">
        <div className="card-purple MarginT30">
   <img src={pl7} style={{height:'25vh',float:'center',width:'100%'}}/>
   <br/> 
   <div style={{paddingTop:'5%'}}>
   <span style={{fontSize:'11px',marginBottom:'0px',fontWeight:'700'}}>"Hamlet Contemplates</span>
   <button className="buds" style={{float:'right',minHeight:'',fontSize:''}}>BUSD</button>
   </div>

   <div className="row p-0 ml-0 mr-0 " style={{marginTop:'3.5%'}}>
    <div className="col-2 p-0">
<img src={s25} style={{height:'4.2vh'}}/>
    </div>
    <div className="col-5">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Creator</p>
  
        <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>John Doe</p>
        </div>
        <div className="col-5 text-right">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Current Bid</p>
  
  <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>200 AIFI</p> 
        </div>
   </div>
   </div> 
        </div>
        <div className="col-md-6 col-lg-3 col-sm-12 MarginTt30">
        <div className="card-purple MarginT30">
   <img src={pl8} style={{height:'25vh',float:'center',width:'100%'}}/>
   <br/> 
   <div style={{paddingTop:'5%'}}>
   <span style={{fontSize:'11px',marginBottom:'0px',fontWeight:'700'}}>"Hamlet Contemplates</span>
   <button className="buds" style={{float:'right',minHeight:'',fontSize:''}}>BUSD</button>
   </div>

   <div className="row p-0 ml-0 mr-0 " style={{marginTop:'3.5%'}}>
    <div className="col-2 p-0">
<img src={s25} style={{height:'4.2vh'}}/>
    </div>
    <div className="col-5">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Creator</p>
  
        <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>John Doe</p>
        </div>
        <div className="col-5 text-right">
        <p style={{fontSize:'10px',marginBottom:'0px',color:'grey'}}>Current Bid</p>
  
  <p style={{fontSize:'10px',marginBottom:'0px',fontWeight:'600'}}>200 AIFI</p> 
        </div>
   </div>
   </div>
        </div>
</div> 
<br/>
<center>
<a href="#" style={{cursor:"pointer",float:''}}>
            <button className="headerGrad" style={{cursor:"pointer",backgroundColor:'#ae46fe ',color:"white",minWidth:'8rem',fontWeight:'600',fontSize:'13px',border:'1px solid #ae46fe',marginLeft:'15px',marginRight:'0px',minHeight:'5vh',marginTop:'20px',marginRight:'10px',borderRadius:".5rem"}}>            
            <span style={{fontSize:"15px"}}>View More</span></button>
            </a>
</center>
</div>
<br/><br/>
<div className="cardJoinCommunity" style={{marginRight:'15px',marginLeft:'15px'}}>
<center>
    <p style={{fontSize:'35px',fontWeight:'700',marginBottom:'20px'}}>Create <span className="fontGrad">Your Own NFT!</span></p>  
    <p style={{fontSize:'15px',color:'#a9a5b1'}}>We have a large scale group to support each other in this game, Join us to get the <br/>
news as soon as possible and follow our latest announcements!</p> 
    
<a href="#" style={{cursor:"pointer"}}>
            <button className="headerGrad" style={{cursor:"pointer",backgroundColor:'#ae46fe ',color:"white",minWidth:'12rem',fontWeight:'600',fontSize:'13px',border:'1px solid #ae46fe',marginLeft:'15px',marginRight:'0px',minHeight:'5vh',marginTop:'15px',marginRight:'10px',borderRadius:".5rem"}}>            
            <span style={{fontSize:"15px"}}> Join Community Now</span></button>
            </a>
    </center>
</div>
<br/><br/>
</div>
<Footer/>
</>
        )
    }
}