import React, { Component } from "react";
import "../../css/style.css";
import { Link } from "react-router-dom";
import { Navbar, Nav, Container, Modal } from "react-bootstrap";
import L from "../../Images/L.png";
import Logo from "../../Images/logo5.png";
export default class Header extends Component {
  state = {
    activePage: "",
    show: false,
    sharkwebWallet: false,
  };

  handleClose = () => {
    this.setState({
      show: false,
      sharkwebWallet: false,
    });
  };

  handleWallet = () => {
    this.setState({
      show: true,
    });
  };
  handleSharkwebWallet = () => {
    this.setState({
      sharkwebWallet: true,
    });
  };
  componentDidMount() {
    var token = window.location.href.split("/")[3];

    this.setState({
      activePage: token,
    });
  }
  render() {
    return (
      <>
        <Navbar
          bg="transparent"
          expand="lg"
          className="p-0 HeadLR"
          style={{ marginTop: "" }}
        >
          <>
            <Navbar.Brand href="/" style={{ fontSize: "22px", color: "white" }}>
              <img src={Logo} style={{ height: "6vh" }} alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              style={{ backgroundColor: "white" }}
            />
            {/* <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto ml-3">
                <Nav.Link
                  href="/"
                  style={{
                    marginRight: "30px",
                    color: this.state.activePage === "" ? "#AA3EFE" : "grey",
                    minWidth: "",
                    fontSize: "16px",
                    fontWeight: this.state.activePage === "" ? "700" : "",
                  }}
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  href="#"
                  style={{
                    marginRight: "30px",
                    color:
                      this.state.activePage === "discover" ? "#AA3EFE" : "grey",
                    minWidth: "",
                    fontSize: "16px",
                    fontWeight:
                      this.state.activePage === "discover" ? "700" : "0",
                  }}
                >
                  Discover
                </Nav.Link>
                <Nav.Link
                  href="#"
                  style={{
                    marginRight: "30px",
                    color:
                      this.state.activePage === "docs" ? "#AA3EFE" : "grey",
                    minWidth: "",
                    fontSize: "16px",
                    fontWeight: this.state.activePage === "docs" ? "700" : "",
                  }}
                >
                  Docs
                </Nav.Link>
                <Nav.Link
                  className="btn btn-secondary"
                  href="#"
                  style={{
                    marginRight: "30px",
                    color:
                      this.state.activePage === "blog" ? "#AA3EFE" : "grey",
                    minWidth: "",
                    fontSize: "16px",
                    fontWeight: this.state.activePage === "blog" ? "700" : "",
                  }}
                >
                  Blog
                </Nav.Link>

                <Nav.Link
                  className="btn btn-secondary"
                  href="#"
                  style={{
                    marginRight: "30px",
                    color:
                      this.state.activePage === "contact" ? "#AA3EFE" : "grey",
                    minWidth: "",
                    fontSize: "16px",
                    fontWeight:
                      this.state.activePage === "contact" ? "700" : "",
                  }}
                >
                  Contact Us
                </Nav.Link>
              </Nav>
              <Nav className="ml-auto">
                <a href="#" style={{ cursor: "pointer" }}>
                  <button
                    onClick={this.handleWallet}
                    className="headerGrad"
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#ae46fe ",
                      color: "white",
                      minWidth: "9rem",
                      fontWeight: "600",
                      fontSize: "16px",
                      border: "1px solid #ae46fe",
                      marginTop: "",
                      marginRight: "0px",
                      minHeight: "5vh",
                      marginRight: "10px",
                      borderRadius: ".5rem",
                    }}
                  >
                    {" "}
                    <span style={{ fontSize: "14px" }}> Connect Wallet</span>
                  </button>
                </a>
              </Nav>
            </Navbar.Collapse> */}
          </>
        </Navbar>
        {/* <Modal show={this.state.show} onHide={this.handleClose} size="lg">
          <Modal.Body>
            <i
              className="fa fa-times"
              onClick={this.handleClose}
              style={{
                position: "absolute",
                right: "3%",
                top: "7%",
                cursor: "pointer",
              }}
            ></i>
            <div style={{ padding: "4%", fontSize: "15px" }}>
              <center>
                {" "}
                <p
                  style={{
                    fontSize: "22px",
                    fontWeight: "700",
                    marginBottom: "5px",
                    color: "",
                  }}
                >
                  {" "}
                  Connect your wallet{" "}
                </p>
                <p style={{ color: "#7b7583", marginBottom: "5px" }}>
                  If you don’t have a wallet, you can create one now.{" "}
                </p>
                <Link
                  to=""
                  style={{
                    color: "#be6dfd",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  Learn More
                </Link>
              </center>

              <hr />
            </div>

            <div
              onClick={this.handleSharkwebWallet}
              className="row Nopad"
              style={{
                backgroundColor: "#f6f6f6",
                paddingLeft: "4%",
                paddingRight: "4%",
                paddingTop: "1%",
                paddingBottom: "1%",
                cursor: "pointer",
              }}
            >
              <div className="col-2 text-center ">
                <img className="H2" src={L} style={{ height: "3.5vh" }} />
              </div>
              <div className="col-6 pl-0">
                <span style={{ fontSize: "", fontWeight: "700" }}>
                  SharkWeb Wallet
                </span>
              </div>
              <div className="col-4 text-center">
                <span
                  style={{
                    fontSize: "",
                    color: "#7b7583",
                    fontWeight: "700",
                    float: "",
                    textAlign: "",
                  }}
                >
                  Popular
                </span>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.sharkwebWallet}
          onHide={this.handleClose}
          size="lg"
        >
          <Modal.Body>
            <i
              className="fa fa-times"
              onClick={this.handleClose}
              style={{
                position: "absolute",
                right: "3%",
                top: "7%",
                cursor: "pointer",
              }}
            ></i>
            <div style={{ padding: "4%", fontSize: "15px" }}>
              <p
                style={{
                  fontSize: "22px",
                  fontWeight: "700",
                  marginBottom: "5px",
                  color: "",
                }}
              >
                {" "}
                SharkWeb wallet{" "}
              </p>
              <p style={{ color: "#7b7583", marginBottom: "5px" }}>
                If you don’t have a wallet, you can create one now.{" "}
                <Link
                  to=""
                  style={{ color: "#be6dfd", fontSize: "", fontWeight: "600" }}
                >
                  Learn More
                </Link>
              </p>

              <hr />
              <div>
                <label style={{ fontWeight: "600" }}>Enter Private Key</label>
                <div
                  className="input-group mb-3"
                  style={{ border: "1px solid #eaeaea", borderRadius: ".5rem" }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your private key"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    style={{ border: "none", fontSize: "13px" }}
                  />
                  <div className="input-group-append" style={{ border: "none" }}>
                    <span
                      className="input-group-text"
                      id="basic-addon2"
                      style={{ backgroundColor: "transparent", border: "none" }}
                    >
                      <i className="fa fa-copy" style={{ cursor: "pointer" }}></i>
                    </span>
                  </div>
                </div>

                <label style={{ fontWeight: "600" }}>Enter Public Key</label>
                <div
                  className="input-group mb-3"
                  style={{ border: "1px solid #eaeaea", borderRadius: ".5rem" }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your public key"
                    style={{ border: "none", fontSize: "13px" }}
                  />
                  <div className="input-group-append" style={{ border: "none" }}>
                    <span
                      className="input-group-text"
                      id="basic-addon2"
                      style={{ backgroundColor: "transparent", border: "none" }}
                    >
                      <i className="fa fa-copy" style={{ cursor: "pointer" }}></i>
                    </span>
                  </div>
                </div>
                <br />
                <a href="#" style={{ cursor: "pointer" }}>
                  <button
                    onClick={this.handleClose}
                    className="headerGrad"
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#ae46fe ",
                      color: "white",
                      minWidth: "100%",
                      fontWeight: "600",
                      fontSize: "16px",
                      border: "1px solid #ae46fe",
                      marginTop: "",
                      marginRight: "0px",
                      minHeight: "5vh",
                      marginRight: "10px",
                      borderRadius: ".25rem",
                    }}
                  >
                    {" "}
                    <span style={{ fontSize: "14px" }}> Connect Wallet</span>
                  </button>
                </a>
              </div>
            </div>
          </Modal.Body>
        </Modal> */}
      </>
    );
  }
}
