import React, { Component, useState } from "react";
import "../css/style.css";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  Button,
  Table,
  Form,
  Row,
  Col,
  Navbar,
  Container,
  NavDropdown,
  Nav,
  Carousel,
} from "react-bootstrap";
import Header from "./Header/Header";
import s21 from "../Images/s21.png";
import s22 from "../Images/s22.png";
import s23 from "../Images/s23.png";
import s24 from "../Images/s24.png";
import s25 from "../Images/s25.png";
import p21 from "../Images/P21.png";
import p22 from "../Images/p22.png";
import p23 from "../Images/p23.png";
import p221 from "../Images/p221.png";
import p222 from "../Images/p222.png";
import p223 from "../Images/p223.png";
import p224 from "../Images/p224.png";
import graph from "../Images/graph.png";
import Footer from "./Footer";
import PurchaseNFT from "./PurchaseNFT";
import { useAuthState } from "../context/context";

const Profile = () => {
  const [error, setError] = useState();
  const state = useAuthState();
  const token = state?.token;
  const listedNFT = state?.listningNFT;
  const { id } = useParams();
  const description = listedNFT?.filter((v) => {
    if (v?._id === id) {
      return v;
    }
  });
  const userInfo = state.useStaus;
  console.log(userInfo);
  //==================================NFT name
  const [nft, setNftName] = useState("");
  const [visibleDiv, setVisibleDiv] = useState(1);
  const showDiv = (e) => {
    setVisibleDiv(e);
  };
  const [show, setShow] = useState(false);
  const showModel = ({
    name,
    transactionsID,
    hash,
    transferPrice,
    newOwnerAddress,
    minValue,
  }) => {
    if (token) {
      setShow(true);
      setError("");
      setNftName({
        name,
        transactionsID,
        hash,
        transferPrice,
        newOwnerAddress,
        minValue,
      });
    } else {
      setError("Login is required!");
      setShow(false);
    }
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      <div className="LandingBg pt-5">
        <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <button
              style={{
                background:
                  visibleDiv === 1
                    ? "linear-gradient(to right, #d201ee, #6e01f7)"
                    : "transparent",
                color: visibleDiv === 1 ? "white" : "#484849",
                border: "none",
                width: visibleDiv === 1 ? "8rem" : "",
                minHeight: "5vh",
                borderRadius: ".5rem",
                fontSize: "15px",
                fontWeight: visibleDiv === 1 ? "600" : "",
                cursor: "pointer",
              }}
              onClick={() => showDiv(1)}
            >
              SELL
            </button>
            <button
              style={{
                background:
                  visibleDiv === 2
                    ? "linear-gradient(to right, #d201ee, #6e01f7)"
                    : "transparent",
                color: visibleDiv === 2 ? "white" : "#484849",
                border: "none",
                width: visibleDiv === 2 ? "8rem" : "",
                minHeight: "5vh",
                borderRadius: ".5rem",
                fontSize: "15px",
                fontWeight: visibleDiv === 2 ? "600" : "",
                marginLeft: "auto",
                marginRight: "auto",
                cursor: "pointer",
              }}
              onClick={() => showDiv(2)}
            >
              SOLD NFT
            </button>
            <button
              style={{
                background:
                  visibleDiv === 3
                    ? "linear-gradient(to right,  #d201ee, #6e01f7)"
                    : "transparent",
                color: visibleDiv === 3 ? "white" : "#484849",
                border: "none",
                width: visibleDiv === 3 ? "8rem" : "",
                minHeight: "5vh",
                borderRadius: ".5rem",
                fontSize: "15px",
                fontWeight: visibleDiv === 3 ? "600" : "",
                marginLeft: "",
                cursor: "pointer",
              }}
              onClick={() => showDiv(3)}
            >
              History
            </button>
          </div>
          <hr style={{ backgroundColor: "#eae7ea" }} />
          <br />
          {visibleDiv === 2 && (
            <div className="tabDiv">
              <div className="row">
                <div className="col-md-6 col-sm-12" style={{ paddingRight: "4%" }}>
                  <div className="row">
                    <div className="col-2 ">
                      <img className="H4vh" src={p221} style={{ height: "6vh" }} />
                    </div>
                    <div className="col-7">
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          marginBottom: "5px",
                        }}
                      >
                        "Hamlet Contemplates ...
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "",
                          marginBottom: "0px",
                        }}
                      >
                        8 hours ago
                      </p>
                    </div>
                    <div className="col-3 text-right">
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          marginBottom: "0px",
                        }}
                      >
                        400 AIFI
                      </p>
                      <p
                        style={{
                          fontSize: "11px",
                          fontWeight: "",
                          marginBottom: "0px",
                        }}
                      >
                        -$12.246
                      </p>
                      <button
                        style={{
                          fontSize: "10px",
                          border: "1px solid #c401f0",
                          borderRadius: ".25rem",
                          minWidth: "4.5rem",
                          minHeight: "2.5vh",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        Place a bid
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-sm-12 M30"
                  style={{ paddingLeft: "4%" }}
                >
                  <div className="row">
                    <div className="col-2">
                      <img className="H4vh" src={p222} style={{ height: "6vh" }} />
                    </div>
                    <div className="col-7">
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          marginBottom: "5px",
                        }}
                      >
                        "Hamlet Contemplates ...
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "",
                          marginBottom: "0px",
                        }}
                      >
                        8 hours ago
                      </p>
                    </div>
                    <div className="col-3 text-right">
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          marginBottom: "0px",
                        }}
                      >
                        400 AIFI
                      </p>
                      <p
                        style={{
                          fontSize: "11px",
                          fontWeight: "",
                          marginBottom: "0px",
                        }}
                      >
                        -$12.246
                      </p>
                      <button
                        style={{
                          fontSize: "10px",
                          border: "1px solid #c401f0",
                          borderRadius: ".25rem",
                          minWidth: "4.5rem",
                          minHeight: "2.5vh",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        Place a bid
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-6 col-sm-12" style={{ paddingRight: "4%" }}>
                  <div className="row ">
                    <div className="col-2 ">
                      <img className="H4vh" src={p223} style={{ height: "6vh" }} />
                    </div>
                    <div className="col-7">
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          marginBottom: "5px",
                        }}
                      >
                        "Hamlet Contemplates ...
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "",
                          marginBottom: "0px",
                        }}
                      >
                        8 hours ago
                      </p>
                    </div>
                    <div className="col-3 text-right">
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          marginBottom: "0px",
                        }}
                      >
                        400 AIFI
                      </p>
                      <p
                        style={{
                          fontSize: "11px",
                          fontWeight: "",
                          marginBottom: "0px",
                        }}
                      >
                        -$12.246
                      </p>
                      <button
                        style={{
                          fontSize: "10px",
                          border: "1px solid #c401f0",
                          borderRadius: ".25rem",
                          minWidth: "4.5rem",
                          minHeight: "2.5vh",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        Place a bid
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-sm-12 M30"
                  style={{ paddingLeft: "4%" }}
                >
                  <div className="row">
                    <div className="col-2">
                      <img className="H4vh" src={p224} style={{ height: "6vh" }} />
                    </div>
                    <div className="col-7">
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          marginBottom: "5px",
                        }}
                      >
                        "Hamlet Contemplates ...
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "",
                          marginBottom: "0px",
                        }}
                      >
                        8 hours ago
                      </p>
                    </div>
                    <div className="col-3 text-right">
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          marginBottom: "0px",
                        }}
                      >
                        400 AIFI
                      </p>
                      <p
                        style={{
                          fontSize: "11px",
                          fontWeight: "",
                          marginBottom: "0px",
                        }}
                      >
                        -$12.246
                      </p>
                      <button
                        style={{
                          fontSize: "10px",
                          border: "1px solid #c401f0",
                          borderRadius: ".25rem",
                          minWidth: "4.5rem",
                          minHeight: "2.5vh",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        Place a bid
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {visibleDiv === 1 && (
            <div className="tabDiv">
              <p style={{ fontSize: "15px", color: "#87818e" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation
              </p>
              <div className="row">
                <div className="col-3">
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    Contact Address
                  </p>
                </div>
                <div className="col-9">
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      marginBottom: "0px",
                    }}
                  >
                    0y48cf...a3c3
                  </p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-3">
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    Token ID
                  </p>
                </div>
                <div className="col-9">
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      marginBottom: "0px",
                    }}
                  >
                    512
                  </p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-3">
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    Token Standard
                  </p>
                </div>
                <div className="col-9">
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      marginBottom: "0px",
                    }}
                  >
                    AIFI-721
                  </p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-3">
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    Blockchain
                  </p>
                </div>
                <div className="col-9">
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      marginBottom: "0px",
                    }}
                  >
                    SharkWeb
                  </p>
                </div>
              </div>
            </div>
          )}

          {visibleDiv === 3 && (
            <div
              className="tabDiv"
              style={{
                backgroundColor: "#fcf9ff",
                padding: "3%",
                borderRadius: ".5rem",
              }}
            >
              <p style={{ fontWeight: "600" }}>All Time Avg. Price</p>
              <img src={graph} style={{ height: "23.5vh", width: "100%" }} />
            </div>
          )}
        </div>
        <br />
        <br />

        <br />
        <br />
        <div
          className="cardJoinCommunity"
          style={{ marginRight: "15px", marginLeft: "15px" }}
        >
          <center>
            <p
              style={{
                fontSize: "35px",
                fontWeight: "700",
                marginBottom: "20px",
              }}
            >
              Create <span className="fontGrad">Your Own NFT!</span>
            </p>
            <p style={{ fontSize: "15px", color: "#a9a5b1" }}>
              We have a large scale group to support each other in this game,
              Join us to get the <br />
              news as soon as possible and follow our latest announcements!
            </p>

            <a href="#" style={{ cursor: "pointer" }}>
              <button
                className="headerGrad"
                style={{
                  cursor: "pointer",
                  backgroundColor: "#ae46fe ",
                  color: "white",
                  minWidth: "12rem",
                  fontWeight: "600",
                  fontSize: "13px",
                  border: "1px solid #ae46fe",
                  marginLeft: "15px",
                  marginRight: "10px",
                  minHeight: "5vh",
                  marginTop: "15px",
                  borderRadius: ".5rem",
                }}
              >
                <span style={{ fontSize: "15px" }}> Join Community Now</span>
              </button>
            </a>
          </center>
        </div>
        <br />
        <br />
      </div>
      <Footer />
      <PurchaseNFT show={show} handleClose={handleClose} nftName={nft} />
    </>
  );
};
export default Profile;
