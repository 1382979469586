import React, { Component, useEffect, useState } from "react";

import "../css/style.css";
import { Link } from "react-router-dom";
import {
  Card,
  Button,
  Table,
  Form,
  Row,
  Col,
  Navbar,
  Container,
  NavDropdown,
  Nav,
  Carousel,
} from "react-bootstrap";
import Header from "./Header/HeaderUnauth";
import s11 from "../Images/s11.png";
import s21 from "../Images/s21.png";
import s22 from "../Images/s22.png";
import s23 from "../Images/s23.png";
import s24 from "../Images/s24.png";
import s25 from "../Images/s25.png";
import s31 from "../Images/s31.png";
import s32 from "../Images/s32.png";
import s33 from "../Images/s33.png";
import s34 from "../Images/s34.png";
import s41 from "../Images/s41.png";
import Footer from "./Footer";
import Vector from "../Images/Vector.png";
import API from "../service/API";
import u1 from "../Images/u1.png";
import u2 from "../Images/u2.png";
import u3 from "../Images/u3.png";
import u4 from "../Images/u4.png";
import Transfer from "../Images/transfer.png";
import Create from "../Images/create nft.png";
import Wallet from "../Images/wallet.png";
import { useAuthState } from "../context/context";
import { OverlayTrigger, Tooltip} from 'react-bootstrap'; // Import necessary components from react-bootstrap

// import dcrypt from "../config";
const Landing = () => {
  const state = useAuthState();
  const listedNFT = state?.listningNFT;
  // console.log(listedNFT)

  const tooltip = (
    <Tooltip id="tooltip" className='tooltip' style={{ wordBreak:'break-all' }}>
      Chain: SharkWeb
    </Tooltip>
  );
  return (
    <>
      <div className="LandingBg">
        <Header />
        <section>
          <div className="row m-0">
            <div className="col-md-5 col-sm-12" style={{ marginTop: "8%" }}>
              <h1 className="text-bold" style={{ fontWeight: "700" }}>
              Discover a New Era of NFTs. <br />
                {/* Crypto Currency */}
              </h1>
              <p style={{ marginTop: "20px" }}>
                {" "}
                Discover the unique features and benefits of SharkPixel NFT Hub, powered by SharkWeb Blockchain technology. 
              </p>
              <p style={{ marginTop: "20px" }}>
                {" "}
                SharkPixel NFT Hub is a blockchain-based NFT marketplace that allows creators to sell their digital art, collectibles, and other unique assets as non-fungible tokens.
              </p>
              <p style={{ marginTop: "20px" }}>
                {" "}
                The platform is designed to be user-friendly and accessible to both creators and collectors.               </p>

              <Link to="/create-nft" style={{ cursor: "pointer" }}>
                <button
                  className="headerGrad"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#ae46fe ",
                    color: "white",
                    minWidth: "9rem",
                    fontWeight: "600",
                    fontSize: "13px",
                    border: "1px solid #ae46fe",
                    marginTop: "15px",
                    marginRight: "0px",
                    minHeight: "5vh",
                    marginRight: "10px",
                    borderRadius: ".5rem",
                  }}
                >
                  <span style={{ fontSize: "15px" }}> Getting Started</span>
                </button>
              </Link>
            </div>
            <div className="col-md-7 col-sm-12 text-right m-0 p-0 TextCenter">
              <img
                className="IHegiht"
                src={s11}
                style={{ height: "45vh", width: "" }}
                alt={s11}
              />
            </div>
          </div>
        </section>
        <br />
        <br />
        <center>
          <img src={Vector} style={{ height: "2vh" }} alt={Vector} />
        </center>
        <section style={{ marginTop: "3%" }}>
          <center>
            <p style={{ fontSize: "45px", fontWeight: "700" }}>
              SharkWeb's <span className="fontGrad">collection</span>
            </p>
          </center>

          <br />

          <div className="row m-0 p-0">
            {listedNFT
              ? listedNFT.map((index,i) => {
                  return (
                    <div key={i} className="mt-2 col-lg-3 col-sm-12 MarginT30">
                      <div className="card-purple" >
                        <div
                          style={{
                            paddingLeft: "3%",
                            paddingRight: "3%",
                            paddingBottom: "",
                           
                          }}
                        >
                           <OverlayTrigger
                              placement="right" // Set the placement to bottom-end for bottom-right position
                              delay={{ show: 250, hide: 400 }}
                              overlay={tooltip}
                              
                            >
                          <a
                              href={`https://pilot.sharkscan.com/nftdetails?${index?.transactionsID}`}
                              target="_blanck"
                            >
                              <img
                                src={u1}
                                alt={"logo"}
                                style={{
                                  height: "2vh",
                                  marginBottom: "8px",
                                  cursor: "pointer",
                                }}
                              />
                            </a>
                          </OverlayTrigger>
                          {/* <img
                          // onClick={}
                            src={u3}
                            style={{
                              height: "1.5vh",
                              float: "right",
                              cursor: "pointer",
                            }}
                          />                           */}
                          <a href={index?.hash} target="_blank">

                          <img
                            src={u2}
                            style={{
                              height: "1.5vh",
                              float: "right",
                              marginRight: "5px",
                              cursor: "pointer",
                            }}
                          />
                          </a>
                        </div>
                        <img
                          src={index?.hash}
                          style={{
                            height: "20vh",
                            width: "100%",
                            marginTop: "",
                            borderRadius: "0.5rem",
                            height: "300px"
                          }}
                        />
                        <br />
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: "10px",
                            marginBottom: "0px",
                          }}
                        >
                          <span style={{ fontSize: "18px", fontWeight: "700" }}>
                            {index.name}
                          </span>
                          <span style={{ float: "right", color: "grey" }}>
                            Owned By{" "}
                            <span
                              style={{ color: "#aa3efe", fontWeight: "500" }}
                            >
                              @{index.nftCreator}
                            </span>
                          </span>
                        </p>
                        <p style={{ fontSize: "13px", margin: "0px" }}>
                          Current Price : {index.minValue}
                        </p>
                        <Link
                          to={`/transfer-nft/${index._id}`}
                          // style={{ color: "white" }}
                        >
                          <button
                            style={{
                              backgroundColor: "#aa3efe",
                              border: "none",
                              borderBottomLeftRadius: "1rem",
                              color: "white",
                              minHeight: "4.5vh",
                              fontWeight: "600",
                              fontSize: "14px",
                              minWidth: "75%",
                              marginTop: "15px",
                              marginRight: "4px",
                              cursor: "pointer",
                            }}
                          >
                            Transfer
                          </button>
                        </Link>

                        <Link
                          to={`/transfer-nft/${index._id}`}
                          // style={{ color: "white" }}
                        >
                        <button
                          style={{
                            backgroundColor: "#aa3efe",
                            border: "none",
                            borderBottomRightRadius: "1rem",
                            color: "white",
                            minHeight: "4.5vh",
                            fontWeight: "500",
                            fontSize: "14px",
                            minWidth: "20%",
                            cursor: "pointer",
                          }}
                        >
                          <img src={u4} style={{ height: "1.5vh" }} />
                        </button>
</Link>
                      </div>
                    </div>
                  );
                })
              : []}

            {/* <div className=" col-lg-3 col-sm-12 MarginT30">
                      <div className="card-purple">
                        <div style={{paddingLeft:'3%',paddingRight:'3%',paddingBottom:''}}>
                        <img src={u1}
                        style={{height:'2vh',marginBottom:'8px'}}/>
                          <img src={u3}
                        style={{height:'1.5vh',float:'right',cursor:'pointer'}}/>
                          <img src={u2}
                        style={{height:'1.5vh',float:'right',marginRight:'5px',cursor:'pointer'}}/>
                        </div>
                    
                     
                      <img src={s21}
                      style={{height:'20vh',
                      width:'100%',
                      marginTop:''}}/>
                      <br/>
                      <p style={{fontSize:'13px',marginTop:'10px',marginBottom:'0px'}}>
                      <span style={{fontSize:'18px',fontWeight:'700'}}>NFT-1</span>
                      <span style={{float:'right',color:'grey'}}>Owned By <span style={{color:'#aa3efe',fontWeight:'500'}}>@Nft-1</span></span>
                      </p>
                      <p style={{fontSize:'13px',margin:'0px'}}>Current Price : 5</p>
                      <button style={{
                        backgroundColor:'#aa3efe',
                        border:'none',
                        borderBottomLeftRadius:'1rem',
                        color:'white',
                        minHeight:'4.5vh',
                        fontWeight:'600',
                        fontSize:'14px',
                        minWidth:'75%',
                        marginTop:'15px',
                        marginRight:'4px',
                        cursor:'pointer'
                      }}>transfer</button>
                        <button style={{
                        backgroundColor:'#aa3efe',
                        border:'none',
                        borderBottomRightRadius:'1rem',
                        color:'white',
                        minHeight:'4.5vh',
                        fontWeight:'500',
                        fontSize:'14px',
                        minWidth:'20%',
                        cursor:'pointer'
                      }}>
                        <img src={u4}
                        style={{height:'1.5vh'}}/>
                      </button>
                      </div>
                      </div>
                      <div className=" col-lg-3 col-sm-12 MarginT30">
                      <div className="card-purple">
                        <div style={{paddingLeft:'3%',paddingRight:'3%',paddingBottom:''}}>
                        <img src={u1}
                        style={{height:'2vh',marginBottom:'8px'}}/>
                          <img src={u3}
                        style={{height:'1.5vh',float:'right',cursor:'pointer'}}/>
                          <img src={u2}
                        style={{height:'1.5vh',float:'right',marginRight:'5px',cursor:'pointer'}}/>
                        </div>
                    
                     
                      <img src={s21}
                      style={{height:'20vh',
                      width:'100%',
                      marginTop:''}}/>
                      <br/>
                      <p style={{fontSize:'13px',marginTop:'10px',marginBottom:'0px'}}>
                      <span style={{fontSize:'18px',fontWeight:'700'}}>NFT-1</span>
                      <span style={{float:'right',color:'grey'}}>Owned By <span style={{color:'#aa3efe',fontWeight:'500'}}>@Nft-1</span></span>
                      </p>
                      <p style={{fontSize:'13px',margin:'0px'}}>Current Price : 5</p>
                      <button style={{
                        backgroundColor:'#aa3efe',
                        border:'none',
                        borderBottomLeftRadius:'1rem',
                        color:'white',
                        minHeight:'4.5vh',
                        fontWeight:'600',
                        fontSize:'14px',
                        minWidth:'75%',
                        marginTop:'15px',
                        marginRight:'4px',
                        cursor:'pointer'
                      }}>transfer</button>
                        <button style={{
                        backgroundColor:'#aa3efe',
                        border:'none',
                        borderBottomRightRadius:'1rem',
                        color:'white',
                        minHeight:'4.5vh',
                        fontWeight:'500',
                        fontSize:'14px',
                        minWidth:'20%',
                        cursor:'pointer'
                      }}>
                        <img src={u4}
                        style={{height:'1.5vh'}}/>
                      </button>
                      </div>
                      </div>
                      <div className=" col-lg-3 col-sm-12 MarginT30">
                      <div className="card-purple">
                        <div style={{paddingLeft:'3%',paddingRight:'3%',paddingBottom:''}}>
                        <img src={u1}
                        style={{height:'2vh',marginBottom:'8px'}}/>
                          <img src={u3}
                        style={{height:'1.5vh',float:'right',cursor:'pointer'}}/>
                          <img src={u2}
                        style={{height:'1.5vh',float:'right',marginRight:'5px',cursor:'pointer'}}/>
                        </div>
                    
                     
                      <img src={s21}
                      style={{height:'20vh',
                      width:'100%',
                      marginTop:''}}/>
                      <br/>
                      <p style={{fontSize:'13px',marginTop:'10px',marginBottom:'0px'}}>
                      <span style={{fontSize:'18px',fontWeight:'700'}}>NFT-1</span>
                      <span style={{float:'right',color:'grey'}}>Owned By <span style={{color:'#aa3efe',fontWeight:'500'}}>@Nft-1</span></span>
                      </p>
                      <p style={{fontSize:'13px',margin:'0px'}}>Current Price : 5</p>
                      <button style={{
                        backgroundColor:'#aa3efe',
                        border:'none',
                        borderBottomLeftRadius:'1rem',
                        color:'white',
                        minHeight:'4.5vh',
                        fontWeight:'600',
                        fontSize:'14px',
                        minWidth:'75%',
                        marginTop:'15px',
                        marginRight:'4px',
                        cursor:'pointer'
                      }}>transfer</button>
                        <button style={{
                        backgroundColor:'#aa3efe',
                        border:'none',
                        borderBottomRightRadius:'1rem',
                        color:'white',
                        minHeight:'4.5vh',
                        fontWeight:'500',
                        fontSize:'14px',
                        minWidth:'20%',
                        cursor:'pointer'
                      }}>
                        <img src={u4}
                        style={{height:'1.5vh'}}/>
                      </button>
                      </div>
                      </div> */}
          </div>
          {/* <center>
            <a href="/signin" style={{ cursor: "pointer" }}>
              <button
                className="headerGrad"
                style={{
                  cursor: "pointer",
                  backgroundColor: "#ae46fe ",
                  color: "white",
                  minWidth: "9rem",
                  fontWeight: "600",
                  fontSize: "13px",
                  border: "1px solid #ae46fe",
                  marginTop: "15px",
                  marginRight: "0px",
                  minHeight: "5vh",
                  marginRight: "10px",
                  borderRadius: ".5rem",
                }}
              >
                <span style={{ fontSize: "15px" }}> View Collection</span>
              </button>
            </a>
          </center> */}
        </section>
        <br />

        <br />
        <center>
          <img src={Vector} style={{ height: "2vh" }} />
        </center>
        <br />
        <br />
        <section>
          <center>
            <p style={{ fontSize: "45px", fontWeight: "700" }}>
              Create & Sell <span className="fontGrad">Your NFTs</span>
            </p> <br />
    
          </center>
          <div className="cardWhitePad">
            <div className="row p-0 m-0">
              <div className="col-md-4 col-sm-12">
                <a
                  href="https://faucet.sharkwallet.app"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <div className="cardWhiteNFT">
                    <center>
                      <img src={Wallet} style={{ height: "5vh" }} />
                      <br />
                      <h2
                        style={{
                          marginBottom: "0px",
                          fontSize: "20px",
                          fontWeight: "700",
                          marginTop: "15px",
                          marginBottom: "15px",
                          marginTop: "20px",
                        }}
                      >
                        Setup Secure Wallet 
                        <br />
                        <p style={{ fontSize: "13px", marginTop: "10px", color: "#8b8692", }}>
                          SharkPixel NFT Hub uses secure wallets for all transactions, ensuring that user assets are protected from theft and fraud.
                    </p>
                      </h2>
                    </center>
                  </div>
                </a>
              </div>
              {/* //============================================ */}

              <div className="col-md-4 col-sm-12">
                <div className="cardWhiteNFT MarginT30">
                  <center>
                    <img src={Create} style={{ height: "5vh" }} />
                    <br />
                    <Link to="/create-nft" style={{ textDecoration: "none" }}>
                      <h2
                        style={{
                          marginBottom: "0px",
                          fontSize: "20px",
                          fontWeight: "700",
                          marginTop: "15px",
                          marginBottom: "15px",
                          marginTop: "20px",
                        }}
                      >
                        Mint/Add Your NFTs 
                        <br />
                        <p style={{ fontSize: "13px", marginTop: "10px", color: "#8b8692", }}>
                        If you are an artist or content creator, SharkPixel NFT Hub provides an opportunity to showcase and monetize your work.
</p>
                      </h2>
                    </Link>
                  </center>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="cardWhiteNFT MarginT30">
                  <center>
                    <img src={Transfer} style={{ height: "5vh" }} />
                    <br />
                    <Link to="/transfer-nft" style={{ textDecoration: "none" }}>
                      <h2
                        style={{
                          marginBottom: "0px",
                          fontSize: "20px",
                          fontWeight: "700",
                          marginTop: "15px",
                          marginBottom: "15px",
                          marginTop: "20px",
                        }}
                      >
                        List Them For Sale 
                        <br />
                        <p style={{ fontSize: "13px", marginTop: "10px", color: "#8b8692", }}>
                        You can easily sell your own NFTs. Simply create go to your wallet and follow the steps to list your NFTs for sale. 
</p>
                      </h2>
                    </Link>
                  </center>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </section>
        <br />
        <br />
        <center>
          <img src={Vector} style={{ height: "2vh" }} />
        </center>
        <br />
        <section>
          <center>
            <p
              style={{
                fontSize: "45px",
                fontWeight: "700",
                marginTop: "10px",
              }}
            >
              Why <span className="fontGrad">choose us?</span>
            </p>
          </center>

          <div className="cardWhitePad">
            <div className="row p-0 m-0">
              <div className="col-md-3 col-sm-12 ">
                <div className="cardWhite">
                  <img src={s31} style={{ height: "5vh" }} />
                  <br />
                  <h2
                    style={{
                      marginBottom: "0px",
                      fontSize: "20px",
                      fontWeight: "700",
                      marginTop: "15px",
                      marginBottom: "15px",
                      marginTop: "20px",
                    }}
                  >
                    Dynamic 
                    <br />
                    <span>Royalty System  </span>
                  </h2>
                  <p
                    style={{
                      fontSize: "13px",
                      marginBottom: "0px",
                      color: "#8b8692",
                    }}
                  >
                  A dynamic royalty system to earn royalties even after selling.                   </p>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="cardWhite" style={{ marginTop: "25%" }}>
                  <img src={s32} style={{ height: "5vh" }} />
                  <br />
                  <h2
                    style={{
                      marginBottom: "0px",
                      fontSize: "20px",
                      fontWeight: "700",
                      marginTop: "15px",
                      marginBottom: "15px",
                      marginTop: "20px",
                    }}
                  >
                    Verified  
                    <br />
                    <span>Authenticity </span>
                  </h2>
                  <p
                    style={{
                      fontSize: "13px",
                      marginBottom: "0px",
                      color: "#8b8692",
                    }}
                  >
                    This ensures that buyers can trust that they are purchasing a genuine and unique item. 
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="cardWhite MarginT50">
                  <img src={s33} style={{ height: "5vh" }} />
                  <br />
                  <h2
                    style={{
                      marginBottom: "0px",
                      fontSize: "20px",
                      fontWeight: "700",
                      marginTop: "15px",
                      marginBottom: "15px",
                      marginTop: "20px",
                    }}
                  >
                    Unique 
                    <br />
                    <span>NFTs </span>
                  </h2>
                  <p
                    style={{
                      fontSize: "13px",
                      marginBottom: "0px",
                      color: "#8b8692",
                    }}
                  >
                    A wide range of unique and rare NFTs, including one-of-a-kind comics, collectibles, and more. 
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="cardWhite" style={{ marginTop: "25%" }}>
                  <img src={s34} style={{ height: "5vh" }} />
                  <br />
                  <h2
                    style={{
                      marginBottom: "0px",
                      fontSize: "20px",
                      fontWeight: "700",
                      marginTop: "15px",
                      marginBottom: "15px",
                      marginTop: "20px",
                    }}
                  >
                    Customizable 
                    <br />
                    <span>Auctions </span>
                  </h2>
                  <p
                    style={{
                      fontSize: "13px",
                      marginBottom: "0px",
                      color: "#8b8692",
                    }}
                  >
                    Allowing creators to set their own auction parameters (e.g. reserve price, auction duration)to give creators more control. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <center>
          <img src={Vector} style={{ height: "2vh" }} />
        </center>
        <br />
        <br />
        <section>
          <div className="cardWhiteDropPad">
            <div
              className="cardWhiteDrop"
              style={{ marginLeft: "15px", marginRight: "15px" }}
            >
              <center>
                <p
                  style={{
                    fontSize: "50px",
                    fontWeight: "700",
                    marginBottom: "5px",
                  }}
                >
                  FOMO?   <span className="fontGrad">No more.</span>
                </p>
                <p style={{ fontSize: "13px", color: "#9c97a2" }}>
                  Stay up-to-date with the latest developments and trends in the NFT market by joining our community of NFT enthusiasts and experts. 
                </p>

               <div className="box-email-design">
                  <input type="email"  placeholder="Enter Your Email Address"/>
                  <button type="submit">Subscribe</button>
               </div>
              </center>
            </div>
          </div>
        </section>
      </div>
      <br />
      <center>
        <img src={Vector} style={{ height: "2vh" }} />
      </center>
      <section>
        <div className="bgLast">
          <center>
            <h2 style={{ fontSize: "70px", fontWeight: "700" }}>
              Let's start <br /> <span className="fontGrad">minting</span>
            </h2>
            <p style={{ color: "grey" }}>
            Explore the diverse range of NFTs available on our Hub and invest in unique digital assets with high potential.
            </p>
            <Link to="/create-nft" style={{ cursor: "pointer" }}>
              <button
                className="headerGrad"
                style={{
                  cursor: "pointer",
                  backgroundColor: "#ae46fe ",
                  color: "white",
                  minWidth: "9rem",
                  fontWeight: "600",
                  fontSize: "13px",
                  border: "1px solid #ae46fe",
                  marginTop: "10px",
                  marginRight: "0px",
                  minHeight: "5.5vh",
                  marginRight: "10px",
                  borderRadius: "2rem",
                }}
              >
                <span style={{ fontSize: "15px" }}> Get Started</span>
              </button>
            </Link>
          </center>
        </div>
      </section>

      <Footer />
    </>
  );
};
export default Landing;
