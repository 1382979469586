import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Signin from "./components/Signin";
import Signup from "./components/Signup";
import Landing from "./components/Landing";
import NftDescription from "./components/NftDescription";
import Home from "./components/home";
import CreateNft from "./components/CreateNft";
import Verification from "./components/Verification";
import { useEffect } from "react";
import API from "./service/API";
import { useAuthDispatch } from "./context/context";
import auth from "./constant";
import Profile from "./components/Profile";
import TransferNFT from "./components/TransferNFT";
function App() {
  const dispatch = useAuthDispatch();
  const nft = async () => {
    dispatch({ type: auth.LISTING_NFT_REQUEST });
    const { data } = await API({
      method: "GET",
      url: "https://nft-bac.sharkweb.com/whiteshark-nft/nft-listing",
      // url: "https://nft-bac.sharkweb.com/whiteshark-nft/nft-listing",
    });
    if (data.success) {
      const nft = data?.nft;
      // console.log("nft", nft);
      dispatch({ type: auth.LISTING_NFT_SUCCESS, payload: nft });
    }
  };
  useEffect(() => {
    nft();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/" element={<Landing />} />
        <Route path="/nft-description/:id" element={<NftDescription />} />
        <Route path="/home" element={<Home />} />
        <Route path="/create-nft" element={<CreateNft />} />
        <Route
          path="/verification/:email/:userName/:walletAddress/*"
          element={<Verification />}
        />
        <Route path="/profile" element={<Profile />} />
        <Route path="/transfer-nft/:id" element={<TransferNFT />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
