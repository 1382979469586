import axios from "axios";
const API = axios.create({ baseURL: process.env.CONNECTION_URL });
console.log("hello every one", process.env.CONNECTION_URL);
API.interceptors.request.use((req) => {
  if (localStorage.getItem("token")) {
    req.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  }
  return req;
});
export default API;
