import React, { useState } from "react";
import "../css/style.css";
import { Link } from "react-router-dom";
import {
  Card,
  Button,
  Table,
  Form,
  Row,
  Col,
  Navbar,
  Container,
  NavDropdown,
  Nav,
  Carousel,
} from "react-bootstrap";
import signin from "../Images/Signin.png";
import Logo from "../Images/logo5.png";
import { userSignin } from "../context/actions";
import { useAuthDispatch, useAuthState } from "../context/context";
import auth from "../constant";
import Loader from "./loader/Loader";
const Signin = () => {
  const dispatch = useAuthDispatch();
  const state = useAuthState();
  const [showPassword, setShowPassword] = useState(false);
  const [userLogin, setUserLogin] = useState({
    email: "",
    password: "",
  });
  const handleChange = (events) => {
    const { name, value } = events.target;
    setUserLogin({ ...userLogin, [name]: value });
  };
  const handleEye = () => {
    setShowPassword(!showPassword);
  };

  //===============================const hanlde signin
  const { email, password } = userLogin;
  const handleSignin = async (e) => {
    e.preventDefault();
    dispatch({ type: auth.LOGIN_REQUEST });

    const user = await userSignin({ email, password });
    if (user?.response?.status === 400) {
      dispatch({ type: auth.ERROR, payload: user?.response?.data?.message });
    } else {
      dispatch({ type: auth.LOGIN_SUCCESS });
      const token = user?.data?.token;
      localStorage.setItem("token", token);
       window.location.replace(user?.data?.url);
    }
  };

  return (
    <>
      <div className="LoginGrad">
        <img
          className="pL"
          src={Logo}
          style={{
            height: "6vh",
            marginBottom: "",
            paddingLeft: "8.5%",
            marginTop: "5%",
          }}
          alt={Logo}
        />

        <div className="row w-100 ml-0 mr-0 mt-3 ">
          <div className="col-md-6 col-lg-7  col-sm-12 mobilePadZero p-0">
            <div className="Login">
              <h1 style={{ fontWeight: "700" }}>Login</h1>
              <p
                style={{ fontSize: "19px", marginTop: "", marginBottom: "0px" }}
              >
                Please fill your detail to access your account
              </p>

              <br />

              <form onSubmit={handleSignin}>
                <div className="form-group">
                  <label
                    for="exampleInputEmail1"
                    style={{ fontSize: "19px", fontWeight: "600" }}
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid #d1cbcf",
                    }}
                  />
                </div>

                <div className="form-group mt-4">
                  <label
                    for="exampleInputEmail1 "
                    style={{ fontSize: "19px", fontWeight: "600" }}
                  >
                    Password
                  </label>
                  <div className="input-group mb-3">
                    <input
                      name="password"
                      type={showPassword ? "text" : "password"}
                      onChange={handleChange}
                      className="form-control"
                      value={password}
                      placeholder="***********"
                      style={{
                        borderRight: "none",
                        backgroundColor: "transparent",
                      }}
                    />

                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        id="basic-addon2"
                        style={{
                          backgroundColor: "transparent",
                          borderLeft: "none",
                          cursor: "pointer",
                        }}
                      >
                        {showPassword ? (
                          <i className="fa fa-eye" onClick={handleEye}></i>
                        ) : (
                          <i
                            className="fa fa-eye-slash "
                            aria-hidden="true"
                            onClick={handleEye}
                            style={{ zIndex: "999" }}
                          ></i>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-check" style={{ marginTop: "30px" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    Remember me
                  </label>
                  <span style={{ float: "right" }}>
                    <u style={{ color: "#aa3efe" }}>
                      <Link to="#" style={{ color: "#aa3efe" }}>
                        Forgot Password ?
                      </Link>
                    </u>
                  </span>
                </div>

                <br />
                <p style={{ color: "red" }}>{state.error}</p>
                <button className="LoginButton" type="submit">
                  {state?.loading ? <Loader /> : "Signin"}
                </button>
              </form>
              <br />
              <br />
              <center>
                <p>
                  Don't have an account?
                  <Link
                    to="/signup"
                    style={{ color: "#aa3efe", marginLeft: "5px" }}
                  >
                    <u>Sign up</u>
                  </Link>
                </p>
              </center>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-5 col-sm-12 img Icenter"
            style={{ padding: "" }}
          >
            <img
              className="ImageH"
              src={signin}
              alt={signin}
              style={{ height: "70vh" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;
