import React, { Component, useRef, useState } from "react";

import Modal from "react-bootstrap/Modal";

import Stack from "react-bootstrap/Stack";
import "../css/style.css";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  Button,
  Table,
  Form,
  Row,
  Col,
  Navbar,
  Container,
  NavDropdown,
  Nav,
  Carousel,
} from "react-bootstrap";
import Header from "./Header/Header";
import s21 from "../Images/s21.png";
import add from "../Images/add.png";
import Footer from "./Footer";

import API from "../service/API";
import { useAuthDispatch, useAuthState } from "../context/context";
import auth from "../constant";
import Loader from "./loader/Loader";
import axios from "axios";

const TransferNFT = () => {
  const [show, setShow] = useState(false);
  const state = useAuthState();
  const listedNFT = state?.listningNFT;
  const { id } = useParams();
  const description = listedNFT?.filter((v) => {
    if (v?._id === id) {
      return v;
    }
  });
  // axios({
  //   method: "POST",
  //   url:
  // })

  const status = "SOLD";
  const nftNameStatus = description[0]?.name;
  const hash = description[0]?.hash;
  const nftPrice = description[0]?.minValue;
  const transactionId = description[0]?.transactionsID;
  const dispatch = useAuthDispatch();
  const [transferNft, setTransferNFT] = useState({
    nftName: nftNameStatus,
    fileHash: hash,
    transactionsID: transactionId,
    transferPrice: nftPrice,
    newOwnerAddress: "",
    nftCreator: "",
  });
  const handleTransferNFT = (events) => {
    const { name, value } = events.target;
    setTransferNFT({ ...transferNft, [name]: value });
  };
  const [errorName, setErrorName] = useState("");
  const {
    nftName,
    transactionsID,
    fileHash,
    newOwnerAddress,
    transferPrice,
    nftCreator,
  } = transferNft;
  const [error, setError] = useState("");
  const handleAnotherOne = () => {
    if (newOwnerAddress.length !== 39) {
      setError("Enter Correct New Owner Address");
      setErrorName("");
    } else if (nftCreator === "") {
      setErrorName("Name is required");
      setError("");
    } else {
      setShow(true);
      setError("");
    }
  };

  const [ownerShipTransfer, setOwnerShipTransfer] = useState({
    privatekey: "",
    pass: "",
  });
  const hanldeOwnerShip = (events) => {
    const { name, value } = events.target;
    setOwnerShipTransfer({ ...ownerShipTransfer, [name]: value });
  };
  const handleClose = () => {
    setOwnerShipTransfer({
      ...ownerShipTransfer,
      privatekey: "",
      pass: "",
    });
    dispatch({ type: auth.ERROR });
    setShow(false);
  };
  const { privatekey, pass } = ownerShipTransfer;
  const submitOwnerShip = (e) => {
    e.preventDefault();

    dispatch({ type: auth.OWNERSHIP_REQUEST });

    axios({
      method: "PUT",
      url: "https://nft-bac.sharkweb.com/whiteshark-nft/ownership-transfer",
      // url: "https://nft-bac.sharkweb.com/whiteshark-nft/ownership-transfer",
      data: {
        nftName,
        nftCreator,
        privatekey,
        pass,
        transactionsID,
        fileHash,
        status,
        transferPrice,
        newOwnerAddress,
        api_key: "dd6564a0-14b3-47cc-8c60-13379e14847a",
        _id: "65607cf5568d95429d8dcd25",
      },
    }).then((res) => {
      if (res?.data?.success) {
        dispatch({
          type: auth.OWNERSHIP_SUCCESS,
          payload: res?.data?.message,
        });
        setTimeout(() => {
          window.location.replace("/");
        }, 900);
      } else {
        // console.log(data,'datelse')
        dispatch({
          type: auth.ERROR,
          payload: res.data?.message || res?.data,
        });
      }
    });
  };

  return (
    <>
      <div className="BgNft">
        <Header />
        <br />

        <div style={{ paddingTop: "3%" }}>
          <p
            style={{
              fontSize: "25px",
              fontWeight: "700",
              marginBottom: "5px",
            }}
          >
            Transfer NFT
          </p>

          <p style={{ fontSize: "13px" }}>Dummy Content</p>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div style={{ height: "auto", padding: "2px" }}>
                <img
                  src={fileHash}
                  alt={fileHash}
                  className="w-100"
                  style={{ width: "100%", borderRadius: "5px" }}
                />
              </div>
            </div>
            {/* //==============form value */}
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="" style={{ fontWeight: "700" }}>
                  New Owner Address
                </label>
                <input
                  type="text"
                  className="form-control "
                  id=""
                  name="newOwnerAddress"
                  value={newOwnerAddress}
                  onChange={handleTransferNFT}
                  aria-describedby=""
                  placeholder="Enter New Owner Wallet Address"
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "14px",
                  }}
                />
              </div>
              <p style={{ color: "red" }}>{error}</p>
              <div className="form-group">
                <label htmlFor="" style={{ fontWeight: "700" }}>
                  NFT Owner Name
                </label>
                <input
                  type="text"
                  className="form-control "
                  id=""
                  name="nftCreator"
                  value={nftCreator}
                  onChange={handleTransferNFT}
                  aria-describedby=""
                  placeholder="Enter NFT Owner Name"
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "14px",
                  }}
                />
              </div>

              <p style={{ color: "red" }}>{errorName}</p>
              <div className="form-group">
                <label htmlFor="" style={{ fontWeight: "700" }}>
                  Min Value*
                </label>
                <input
                  type=""
                  className="form-control "
                  id=""
                  name="transferPrice"
                  value={transferPrice}
                  onChange={handleTransferNFT}
                  aria-describedby=""
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "14px",
                  }}
                />
              </div>
              <button
                className="headerGrad"
                style={{
                  cursor: "pointer",
                  backgroundColor: "#ae46fe ",
                  marginTop: "15px",
                  color: "white",
                  minWidth: "100%",
                  fontWeight: "600",
                  fontSize: "16px",
                  border: "1px solid #ae46fe",
                  marginRight: "10px",
                  minHeight: "5vh",
                  borderRadius: ".25rem",
                }}
                onClick={handleAnotherOne}
              >
                Transfer NFT
              </button>
            </div>
          </div>
        </div>

        {/* //===========================new popup */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body className="mt-5 mb-5">
            <Form onSubmit={submitOwnerShip}>
              <Form.Group className="mb-3">
                <Form.Label>Private key</Form.Label>
                <Form.Control
                  type="text"
                  style={{ borderRadius: "20px" }}
                  name="privatekey"
                  placeholder="Enter private key"
                  value={privatekey}
                  onChange={hanldeOwnerShip}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Passphrase</Form.Label>
                <Form.Control
                  type="text"
                  style={{ borderRadius: "20px" }}
                  name="pass"
                  placeholder="Enter passpharse"
                  value={pass}
                  onChange={hanldeOwnerShip}
                  required
                />
              </Form.Group>

              <p style={{ color: "red", textAlign: "center" }}>{state.error}</p>
              <p style={{ color: "green", textAlign: "center" }}>
                {state?.message}
              </p>
              <Stack
                direction="horizontal"
                className="d-flex justify-content-around"
              >
                <Button
                  variant="secondary"
                  className="w-50"
                  style={{ borderRadius: "20px", width: "48%" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  style={{ borderRadius: "20px", width: "48%" }}
                  type={"submit"}
                  disabled={state?.loading}
                >
                  {state?.loading ? <Loader /> : "Transfer NFT"}
                </Button>
              </Stack>
            </Form>
          </Modal.Body>
        </Modal>

        <br />
        <div
          className="cardJoinCommunity"
          style={{
            marginRight: "15px",
            marginLeft: "15px",
            marginTop: "30px",
          }}
        >
          <center>
            <p
              style={{
                fontSize: "35px",
                fontWeight: "700",
                marginBottom: "20px",
              }}
            >
              Become <span className="fontGrad">a Creator</span>
            </p>
            <p style={{ fontSize: "15px", color: "#a9a5b1" }}>
              If you are an artist or content creator, SharkPixel NFT Hub
              provides an opportunity to showcase and monetize your work. By
              minting your creations as NFTs, you can earn royalties every time
              your work is sold on the Hub.
            </p>

            <Link to="#" style={{ cursor: "pointer" }}>
              <button
                className="headerGrad"
                style={{
                  cursor: "pointer",
                  backgroundColor: "#ae46fe ",
                  color: "white",
                  minWidth: "12rem",
                  fontWeight: "600",
                  fontSize: "13px",
                  border: "1px solid #ae46fe",
                  marginLeft: "15px",
                  marginRight: "10px",
                  minHeight: "5vh",
                  marginTop: "15px",
                  borderRadius: ".5rem",
                }}
              >
                <span style={{ fontSize: "15px" }}> Join Community Now</span>
              </button>
            </Link>
          </center>
        </div>
        <br />
        <br />
      </div>
      <Footer />
    </>
  );
};
export default TransferNFT;
