import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuthDispatch, useAuthState } from "../context/context";
import auth from "../constant";
import { userVerification } from "../context/actions";
const Verification = () => {
  const dispatch = useAuthDispatch();
  const state = useAuthState();
  const params = useParams();
  console.log("user params", params);
  const userName = params["userName"];
  const walletAddress = params["walletAddress"];
  const password = params["*"];
  const email = params["email"];
  //=======================================
  const userVerified = async () => {
    dispatch({ type: auth.REGISTER_REQUEST });
    const { data } = await userVerification({
      email,
      password,
      userName,
      walletAddress,
    });
    if (data?.success) {
      window.location.replace("/signin");
    } else {
      window.location.replace("/signin");
    }
  };

  useEffect(() => {
    let isApiSubscribed = true;
    userVerified();
    return () => {
      // cancel the subscription
      isApiSubscribed = false;
    };
  }, [email, password]);

  return (
    <div className="verification">
      <p style={{ color: "red" }}>{state.error}</p>
      <p style={{ color: "green" }}>{state.message}</p>
    </div>
  );
};

export default Verification;
