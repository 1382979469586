import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { useState } from "react";
import { purchaseNFT } from "../context/actions";
import { useAuthDispatch, useAuthState } from "../context/context";
import auth from "../constant";
import API from "../service/API";
import Loader from "./loader/Loader";
import axios from "axios";

const PurchaseNFT = ({ show, handleClose, nftName }) => {
  const state = useAuthState();
  const { hash, minValue, newOwnerAddress, nftCreator } = nftName;
  console.log("file hash", hash);
  console.log("min value", minValue);
  console.log("new owner address", newOwnerAddress);
  console.log("nft creator", nftCreator);

  const dispatch = useAuthDispatch();
  const [purchase, setPurchase] = useState({
    privateKey: "",
    passpharse: "",
  });
  const handlePurchase = (event) => {
    const { name, value } = event.target;
    setPurchase({ ...purchase, [name]: value });
  };
  const { privateKey, passpharse } = purchase;
  //==================================================
  const handlePurchaseNft = async (e) => {
    e.preventDefault();
    //==========================================promise base
    dispatch({ type: auth.OWNERSHIP_REQUEST });
    purchaseNFT({ privateKey, passpharse, newOwnerAddress, minValue })
      .then((paymentInfo) => {
        //============================payment info from blockChaian
        console.log("paymentInfo", paymentInfo);

        const senderAddress = paymentInfo?.data?.TxnData?.sender;
        console.log("Sender Address", senderAddress);
        const transactionId = paymentInfo?.data?.TxnData?.transactionId;
        console.log("transaction Id", transactionId);
        if (transactionId) {
          axios({
            method: "POST",
            url: "https://testnet.sharkweb.com/ownership",
            data: {
              privatekey: privateKey,
              pass: passpharse,
              transactionsID: transactionId,
              fileHash: hash,
              status: "SOLD",
              transferPrice: minValue,
              newOwnerAddress: senderAddress,
            },
          }).then((ownerShipTransfer) => {
            console.log(ownerShipTransfer);
            // const { data } = ownerShipTransfer?.data?.TxnData;
          });
        } else {
          dispatch({
            type: auth.ERROR,
            payload: "Error in payment BLockChain API",
          });
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="mt-5 mb-5">
          <Form onSubmit={handlePurchaseNft}>
            <Form.Group className="mb-3">
              <Form.Label>Private key</Form.Label>
              <Form.Control
                type="text"
                style={{ borderRadius: "20px" }}
                name="privateKey"
                placeholder="Enter private key"
                value={privateKey}
                onChange={handlePurchase}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Passphrase</Form.Label>
              <Form.Control
                type="text"
                style={{ borderRadius: "20px" }}
                name="passpharse"
                placeholder="Enter passpharse"
                value={passpharse}
                onChange={handlePurchase}
                required
              />
            </Form.Group>
            <p style={{ color: "red", textAlign: "center" }}>{state.error}</p>
            <p style={{ color: "green", textAlign: "center" }}>
              {state?.message}
            </p>
            <Stack
              direction="horizontal"
              className="d-flex justify-content-around"
            >
              <Button
                variant="secondary"
                className="w-50"
                style={{ borderRadius: "20px", width: "48%" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                style={{ borderRadius: "20px", width: "48%" }}
                type={"submit"}
              >
                {state?.loading ? <Loader /> : " Purchased"}
              </Button>
            </Stack>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PurchaseNFT;
