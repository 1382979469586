import React, { useState } from "react";
import "../css/style.css";
import { Link } from "react-router-dom";
import {
  Card,
  Button,
  Table,
  Form,
  Row,
  Col,
  Navbar,
  Container,
  NavDropdown,
  Nav,
  Carousel,
} from "react-bootstrap";
import signin from "../Images/Signin.png";
import Logo from "../Images/logo5.png";
import { userSignup } from "../context/actions";
import { useAuthDispatch, useAuthState } from "../context/context";
import auth from "../constant";
import Loader from "./loader/Loader";

const Signup = () => {
  const dispatch = useAuthDispatch();
  const state = useAuthState();
  const [registerUser, setRegisterUser] = useState({
    email: "",
    userName: "",
    wallletAddress: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const handleEye = () => {
    setShowPassword(!showPassword);
  };
  const handleChange = (events) => {
    const { name, value } = events.target;
    setRegisterUser({ ...registerUser, [name]: value });
  };
  //===================================
  const { email, password, confirmPassword, userName, wallletAddress } =
    registerUser;

  const handleUserRegister = async (e) => {
    e.preventDefault();
    dispatch({ type: auth?.REGISTER_REQUEST });
    try {
      const user = await userSignup({
        email,
        password,
        confirmPassword,
        userName,
        wallletAddress,
      });
      if (user?.response?.status === 400) {
        dispatch({ type: auth.ERROR, payload: user?.response?.data?.message });
      } else {
        const { data } = user;
        dispatch({ type: auth?.REGISTER_SUCCESS, payload: data?.message });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCEye = () => {
    setShowCPassword(!showCPassword);
  };
  return (
    <>
      <div className="LoginGrad">
        <img
          className="pL"
          src={Logo}
          alt={Logo}
          style={{
            height: "6vh",
            marginBottom: "",
            paddingLeft: "8.5%",
            marginTop: "4%",
          }}
        />

        <div className="row w-100 ml-0 mr-0 mt-3 ">
          <div className="col-md-6 col-lg-7  col-sm-12 mobilePadZero p-0">
            <div className="Login">
              <h1 style={{ fontWeight: "700" }}>Sign Up</h1>
              <p
                style={{
                  fontSize: "19px",
                  marginTop: "",
                  marginBottom: "0px",
                }}
              >
                Please fill your detail to access your account
              </p>

              <br />

              <form onSubmit={handleUserRegister}>
                <div className="form-group">
                  <label
                    for="exampleInputEmail1"
                    style={{ fontSize: "19px", fontWeight: "600" }}
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    onChange={handleChange}
                    value={email}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid #d1cbcf",
                    }}
                  />
                </div>

                <div className="form-group">
                  <label
                    for="exampleInputEmail1"
                    style={{ fontSize: "19px", fontWeight: "600" }}
                  >
                    User Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="userName"
                    onChange={handleChange}
                    value={userName}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Your User Name"
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid #d1cbcf",
                    }}
                  />
                </div>

                <div className="form-group">
                  <label
                    for="exampleInputEmail1"
                    style={{ fontSize: "19px", fontWeight: "600" }}
                  >
                    Wallet Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="wallletAddress"
                    onChange={handleChange}
                    value={wallletAddress}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter your wallet address"
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid #d1cbcf",
                    }}
                  />
                </div>

                <div className="form-group mt-4">
                  <label
                    for="exampleInputEmail1 "
                    style={{ fontSize: "19px", fontWeight: "600" }}
                  >
                    Password
                  </label>
                  <div className="input-group mb-3">
                    <input
                      name="password"
                      type={showPassword ? "text" : "password"}
                      valu={password}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="***********"
                      style={{
                        borderRight: "none",
                        backgroundColor: "transparent",
                      }}
                    />

                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        id="basic-addon2"
                        style={{
                          backgroundColor: "transparent",
                          borderLeft: "none",
                          cursor: "pointer",
                        }}
                      >
                        {showPassword ? (
                          <i className="fa fa-eye" onClick={handleEye}></i>
                        ) : (
                          <i
                            className="fa fa-eye-slash "
                            aria-hidden="true"
                            onClick={handleEye}
                            style={{ zIndex: "999" }}
                          ></i>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-group mt-4">
                  <label
                    for="exampleInputEmail1 "
                    style={{ fontSize: "19px", fontWeight: "600" }}
                  >
                    Confirm Password
                  </label>
                  <div className="input-group mb-3">
                    <input
                      name="confirmPassword"
                      value={confirmPassword}
                      type={showCPassword ? "text" : "password"}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="***********"
                      style={{
                        borderRight: "none",
                        backgroundColor: "transparent",
                      }}
                    />

                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        id="basic-addon2"
                        style={{
                          backgroundColor: "transparent",
                          borderLeft: "none",
                          cursor: "pointer",
                        }}
                      >
                        {showCPassword ? (
                          <i className="fa fa-eye" onClick={handleCEye}></i>
                        ) : (
                          <i
                            className="fa fa-eye-slash "
                            aria-hidden="true"
                            onClick={handleCEye}
                            style={{ zIndex: "999" }}
                          ></i>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <br />
                <p style={{ color: "red" }}>{state.error}</p>
                <p style={{ color: "green" }}>{state.message}</p>
                <button className="SignupButton" type="submit">
                  {state?.loading ? <Loader /> : "Sign Up"}
                </button>
              </form>

              <br />
              <br />
              <center>
                <p>
                  Already have an account?
                  <Link
                    to="/signin"
                    style={{ color: "#aa3efe", marginLeft: "5px" }}
                  >
                    <u>Sign in</u>
                  </Link>
                </p>
              </center>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-5 col-sm-12 img Icenter"
            style={{ padding: "" }}
          >
            <img
              className="ImageH"
              src={signin}
              alt={signin}
              style={{ height: "70vh" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Signup;
