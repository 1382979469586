import auth from "../constant";
const token = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;
// console.log("token info", token);
export const initialState = {
  user: null,
  error: "",
  loading: false,
  token,
  message: "",
  listningNFT: [],
  users: [],
};
export const AuthReducer = (state, action) => {
  switch (action.type) {
    case auth.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        message: "",
      };
    case auth.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.shopName,
        // token: action.token,
        error: "",
        loading: false,
        message: "",
      };
    case auth.REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        message: "",
      };
    case auth.REGISTER_SUCCESS:
      return {
        ...state,
        user: null,
        token: null,
        error: "",
        loading: false,
        message: action.payload,
      };
    case auth.LGOUT:
      return {
        ...state,
        user: null,
        token: null,
        error: "",
        loading: false,
        message: "",
      };
    case auth.ERROR:
      return {
        ...state,
        user: null,
        loading: false,
        error: action.payload,
        message: "",
        listningNFT: [],
      };
    case auth.NFT_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        message: "",
      };
    case auth.NFT_SUCCESS:
      return {
        ...state,
        user: null,
        // token: null,
        error: "",
        loading: false,
        message: action.payload,
      };
    case auth.LISTING_NFT_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        message: "",
        listningNFT: [],
      };
    case auth.LISTING_NFT_SUCCESS:
      return {
        ...state,
        user: null,
        // token: null,
        error: "",
        loading: false,
        message: "",
        listningNFT: action.payload,
      };
    case auth.OWNERSHIP_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        message: "",
      };
    case auth.OWNERSHIP_SUCCESS:
      return {
        ...state,
        user: null,
        error: "",
        loading: false,
        message: action.payload,
      };
    case auth.USER_STATUS:
      return {
        ...state,
        error: "",
        users: action.payload,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
