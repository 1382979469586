import React, { Component, useRef, useState } from "react";

import Modal from "react-bootstrap/Modal";

import Stack from "react-bootstrap/Stack";
import "../css/style.css";
import { Link } from "react-router-dom";
import {
  Card,
  Button,
  Table,
  Form,
  Row,
  Col,
  Navbar,
  Container,
  NavDropdown,
  Nav,
  Carousel,
} from "react-bootstrap";
import Header from "./Header/Header";
import s21 from "../Images/s21.png";
import add from "../Images/add.png";
import Footer from "./Footer";
import { createNFT } from "../context/actions";
import API from "../service/API";
import { useAuthDispatch, useAuthState } from "../context/context";
import auth from "../constant";
import Loader from "./loader/Loader";
import axios from "axios";

const CreateNft = () => {
  const [show, setShow] = useState(false);
  const [showDefaultContent, setShowDefaultContent] = useState(true);
  const [purchase, setPurchase] = useState({
    privateKey: "",
    passpharse: "",
  });
  const handlePurchase = (event) => {
    const { name, value } = event.target;
    setPurchase({ ...purchase, [name]: value });
  };
  const state = useAuthState();
  const dispatch = useAuthDispatch();
  const [err, setError] = useState("");
  const [nft, setNFT] = useState({
    name: "",
    nftCreator: "",
    supply: "",
    description: "",
    externalLink: "",
  });
  const [file, setSelectedFile] = useState(null);

  const fileInputRef = useRef("");
  // const handleFileUpload = (e) => {
  //   setSelectedFile(e.target.files[0]);
  // };
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileUpload = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    // console.log(file,'filename')
    setSelectedFile(file);
    // Use FileReader to read the selected image and set it to the state
    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleNFT = (events) => {
    const { name, value } = events.target;
    setNFT({ ...nft, [name]: value });
  };
  const handleCloseImage = () => {
    setSelectedImage(null);
    setShowDefaultContent(true);
  };

  const handleMinValue = () => {
    if (supply <= 0) {
      setError("Minimum value must be 1");
    } else {
      setError("");
    }
  };

  const { privateKey, passpharse } = purchase;
  const { name, nftCreator, supply, description, externalLink } = nft;

  const [nameError, setNameError] = useState("");
  const [creators, setCreators] = useState("");

  const handleAnotherOne = () => {
    if (name === "") {
      setNameError("Nft Name is required");
      setError("");
      setCreators("");
    } else if (nftCreator === "") {
      setCreators("Creator Name is required");
      setError("");
      setNameError("");
    } else if (supply <= 0) {
      setError("Minimum value must be 1");
      setNameError("");
      setCreators("");
    } else {
      setCreators("");
      setError("");
      setNameError("");
      setShow(true);
    }
  };
  const handleClose = () => {
    setPurchase({
      ...purchase,
      privateKey: "",
      passpharse: "",
    });
    dispatch({
      type: auth.ERROR,
      payload: "",
    });
    setShow(false);
  };

  const handlePurchaseNft = async (e) => {
    e.preventDefault();
    dispatch({ type: auth.NFT_REQUEST });

    const formData = new FormData();
    formData.append("nft", file);
    formData.append("name", name);
    formData.append("nftCreator", nftCreator);
    formData.append("description", description);
    formData.append("externalLink", externalLink);
    formData.append("minValue", supply);
    formData.append("privatekey", privateKey);
    formData.append("passpharse", passpharse);
    formData.append("api_key", "dd6564a0-14b3-47cc-8c60-13379e14847a");
    formData.append("_id", "65607cf5568d95429d8dcd25");
    await axios
      // .post("https://backend-nc.sharkweb.com/api/create_nft", formData, {
      .post("https://deploy.sharkweb.com/api/create_nft", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      .then((dataResponse) => {
        // console.log(dataResponse);
        if (dataResponse?.data?.success) {
          dispatch({
            type: auth.NFT_SUCCESS,
            payload: "NFT Successfully Created!",
          });
          setTimeout(() => {
            window.location.replace("/");
          }, 900);
        } else {
          dispatch({
            type: auth.ERROR,
            payload: dataResponse.data,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className="BgNft">
        <Header />
        <br />
        <form onSubmit={(e) => e.preventDefault()}>
          <div style={{ paddingTop: "3%" }}>
            <p
              style={{
                fontSize: "25px",
                fontWeight: "700",
                marginBottom: "5px",
              }}
            >
              Create an NFT
            </p>

            <p style={{ fontSize: "13px" }}>
              Once your item is minted you will not be able to change any of its
              information.
            </p>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                {selectedImage ? (
                  <>
                    <div className="nftUplaodafter">
                      <center className="" style={{ fontSize: "13px" }}>
                        <p
                          style={{
                            marginBottom: "0px",
                            float: "right",
                            cursor: "pointer",
                            position: "absolute",
                            top: "2%",
                            right: "5%",
                          }}
                        >
                          <i
                            className="fa fa-close"
                            onClick={handleCloseImage}
                            style={{ fontSize: "18px" }}
                          ></i>
                        </p>
                        <img
                          src={selectedImage}
                          alt="Selected Image"
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                        />
                      </center>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="nftUplaod">
                      <center
                        className="absCenter"
                        style={{ fontSize: "13px" }}
                      >
                        <i
                          className="fa fa-upload"
                          style={{ fontSize: "30px" }}
                        ></i>
                        <p
                          style={{
                            fontWeight: "600",
                            marginBottom: "0px",
                            marginTop: "5px",
                          }}
                        >
                          Drag and drop media
                        </p>
                        <input
                          type="file"
                          // name=""
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleFileUpload}
                        />
                        <Link
                          to=""
                          onClick={handleFileSelect}
                          style={{ color: "#b250fe" }}
                        >
                          Browse files
                        </Link>
                        <p style={{ marginBottom: "0px" }}>Max size: 50MB</p>
                        <p>JPG, PNG, GIF, SVG, MP4</p>
                      </center>
                    </div>
                  </>
                )}
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label for="" style={{ fontWeight: "700" }}>
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control "
                    id=""
                    name="name"
                    value={name}
                    onChange={handleNFT}
                    aria-describedby=""
                    placeholder="Enter your NFT Name"
                    style={{
                      backgroundColor: "transparent",
                      fontSize: "14px",
                    }}
                  />
                </div>
                <p style={{ color: "red" }}>
                  {" "}
                  {nameError !== "" ? nameError : ""}
                </p>
                <div className="form-group">
                  <label for="" style={{ fontWeight: "700" }}>
                    NFT Creator
                  </label>
                  <input
                    type="text"
                    className="form-control "
                    id=""
                    name="nftCreator"
                    value={nftCreator}
                    onChange={handleNFT}
                    aria-describedby=""
                    placeholder="Enter NFT Creater Name"
                    style={{
                      backgroundColor: "transparent",
                      fontSize: "14px",
                    }}
                  />
                </div>
                <p style={{ color: "red" }}>
                  {" "}
                  {creators !== "" ? creators : ""}
                </p>
                <div className="form-group">
                  <label for="" style={{ fontWeight: "700" }}>
                    Min Value
                  </label>
                  <input
                    type=""
                    className="form-control "
                    id=""
                    name="supply"
                    value={supply}
                    onChange={handleNFT}
                    aria-describedby=""
                    placeholder="1"
                    style={{
                      backgroundColor: "transparent",
                      fontSize: "14px",
                    }}
                    onBlur={handleMinValue}
                  />
                </div>
                <p style={{ color: "red" }}> {err !== "" ? err : ""}</p>
                <div className="form-group">
                  <label for="" style={{ fontWeight: "700" }}>
                    Description
                  </label>
                  <textarea
                    type=""
                    className="form-control "
                    id=""
                    name="description"
                    value={description}
                    onChange={handleNFT}
                    aria-describedby=""
                    placeholder="Enter a description"
                    style={{
                      backgroundColor: "transparent",
                      fontSize: "14px",
                      minHeight: "15vh",
                    }}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label for="" style={{ fontWeight: "700" }}>
                    External Links
                  </label>
                  <input
                    type="url"
                    className="form-control"
                    id=""
                    name="externalLink"
                    value={externalLink}
                    onChange={handleNFT}
                    aria-describedby=""
                    placeholder="https://example.com"
                    style={{
                      backgroundColor: "transparent",
                      fontSize: "14px",
                    }}
                  />
                </div>

                <button
                  className="headerGrad"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#ae46fe ",
                    marginTop: "15px",
                    color: "white",
                    minWidth: "100%",
                    fontWeight: "600",
                    fontSize: "16px",
                    border: "1px solid #ae46fe",
                    marginRight: "10px",
                    minHeight: "5vh",
                    borderRadius: ".25rem",
                  }}
                  onClick={handleAnotherOne}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </form>

        <br />
        <div
          className="cardJoinCommunity"
          style={{
            marginRight: "15px",
            marginLeft: "15px",
            marginTop: "30px",
          }}
        >
          <center>
            <p
              style={{
                fontSize: "35px",
                fontWeight: "700",
                marginBottom: "20px",
              }}
            >
              Get Involved <span className="fontGrad">With Your NFTs</span>
            </p>
            <p style={{ fontSize: "15px", color: "#a9a5b1" }}>
              SharkPixel NFT Hub is a community-driven platform that relies on
              user participation and support.
            </p>

            <Link to="#" style={{ cursor: "pointer" }}>
              <button
                className="headerGrad"
                style={{
                  cursor: "pointer",
                  backgroundColor: "#ae46fe ",
                  color: "white",
                  minWidth: "12rem",
                  fontWeight: "600",
                  fontSize: "13px",
                  border: "1px solid #ae46fe",
                  marginLeft: "15px",
                  marginRight: "0px",
                  minHeight: "5vh",
                  marginTop: "15px",
                  marginRight: "10px",
                  borderRadius: ".5rem",
                }}
              >
                <span style={{ fontSize: "15px" }}> Join Community Now</span>
              </button>
            </Link>
          </center>
        </div>
        <br />
        <br />
      </div>
      <Footer />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="mt-5 mb-5">
          <Form onSubmit={handlePurchaseNft}>
            <Form.Group className="mb-3">
              <Form.Label>Private key</Form.Label>
              <Form.Control
                type="text"
                style={{ borderRadius: "20px" }}
                name="privateKey"
                placeholder="Enter private key"
                value={privateKey}
                onChange={handlePurchase}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Passphrase</Form.Label>
              <Form.Control
                type="text"
                style={{ borderRadius: "20px" }}
                name="passpharse"
                placeholder="Enter passpharse"
                value={passpharse}
                onChange={handlePurchase}
              />
            </Form.Group>

            <p style={{ color: "red", textAlign: "center" }}>{state.error}</p>
            <p style={{ color: "green", textAlign: "center" }}>
              {state?.message}
            </p>
            <Stack
              direction="horizontal"
              className="d-flex justify-content-around"
            >
              <Button
                variant="secondary"
                className="w-50"
                style={{ borderRadius: "20px", width: "48%" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                style={{ borderRadius: "20px", width: "48%" }}
                type={"submit"}
                disabled={state?.loading}
              >
                {state?.loading ? <Loader /> : " Create"}
              </Button>
            </Stack>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CreateNft;
