import React, { Component, useState } from "react";
import "../css/style.css";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  Button,
  Table,
  Form,
  Row,
  Col,
  Navbar,
  Container,
  NavDropdown,
  Nav,
  Carousel,
} from "react-bootstrap";
import Header from "./Header/Header";
import s21 from "../Images/s21.png";
import s22 from "../Images/s22.png";
import s23 from "../Images/s23.png";
import s24 from "../Images/s24.png";
import s25 from "../Images/s25.png";
import p21 from "../Images/P21.png";
import p22 from "../Images/p22.png";
import p23 from "../Images/p23.png";
import p221 from "../Images/p221.png";
import p222 from "../Images/p222.png";
import p223 from "../Images/p223.png";
import p224 from "../Images/p224.png";
import graph from "../Images/graph.png";
import Footer from "./Footer";
import PurchaseNFT from "./PurchaseNFT";
import { useAuthState } from "../context/context";

const NftDescription = () => {
  const [error, setError] = useState();
  const state = useAuthState();
  const token = state?.token;
  const listedNFT = state?.listningNFT;
  const { id } = useParams();
  const description = listedNFT?.filter((v) => {
    if (v?._id === id) {
      return v;
    }
  });

  //==================================NFT name
  const [nft, setNftName] = useState("");
  const [visibleDiv, setVisibleDiv] = useState(1);
  const showDiv = (e) => {
    setVisibleDiv(e);
  };
  const [show, setShow] = useState(false);
  const showModel = ({
    name,
    transactionsID,
    hash,
    transferPrice,
    newOwnerAddress,
    minValue,
    nftCreator,
  }) => {
    if (token) {
      setShow(true);
      setError("");
      setNftName({
        name,
        transactionsID,
        hash,
        transferPrice,
        newOwnerAddress,
        minValue,
        nftCreator,
      });
    } else {
      setError("Login is required!");
      setShow(false);
    }
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      <div className="LandingBg">
        <Header />
        <br />
        {description &&
          description?.map((v) => {
            const {
              name,
              transactionsID,
              hash,
              transferPrice,
              newOwnerAddress,
              minValue,
              nftCreator,
            } = v;
            return (
              <>
                <div style={{ paddingTop: "5%", paddingBottom: "5%" }}>
                  <div className="row p-0 m-0">
                    <div className="col-md-12 col-lg-7 col-sm-12">
                      <img
                        className="H21vh"
                        src={v?.hash}
                        style={{ height: "45vh" }}
                        alt={v?.name}
                      />
                    </div>
                    <div className="col-md-12 col-lg-5 col-sm-12">
                      <p style={{ fontSize: "35px", fontWeight: "700" }}>
                        #{v?.name}
                      </p>
                      <p style={{ fontSize: "14px", color: "#817d82" }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                      <div className="row">
                        <div className="col-6">
                          <p
                            style={{
                              fontSize: "13px",
                              color: "#817d82",
                              marginBottom: "0px",
                            }}
                          >
                            Price Bid
                          </p>
                          <p style={{ fontSize: "16px", fontWeight: "700" }}>
                            {v?.minValue} AIFI
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          backgroundColor: "#f4ecff",
                          borderRadius: ".5rem",
                          padding: "4%",
                          marginTop: "15px",
                          marginBottom: "15px",
                        }}
                      >
                        <div className="row">
                          <div className="col-12">
                            <div className="row mb-2">
                              <div className="col-3">
                                <img
                                  src={p22}
                                  style={{ height: "5.5vh" }}
                                  alt={p22}
                                />
                              </div>
                              <div className="col-9">
                                <p
                                  style={{
                                    fontSize: "13px",
                                    color: "#817d82",
                                    marginBottom: "0px",
                                  }}
                                >
                                  Creator
                                </p>
                                <p
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    marginBottom: "0px",
                                  }}
                                >
                                  {v?.address}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-3">
                                <img
                                  src={p23}
                                  style={{ height: "5.5vh" }}
                                  alt={p23}
                                />
                              </div>
                              <div className="col-9">
                                <p
                                  style={{
                                    fontSize: "13px",
                                    color: "#817d82",
                                    marginBottom: "0px",
                                  }}
                                >
                                  Owner
                                </p>
                                <p
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    marginBottom: "0px",
                                  }}
                                >
                                  {v?.newOwnerAddress}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <p style={{ color: "red", textAlign: "center" }}>
                        {error}
                      </p>
                      <button
                        className="MinW"
                        style={{
                          cursor: "pointer",
                          backgroundColor: "transparent ",
                          color: "black",
                          minWidth: "48.5%",
                          fontWeight: "600",
                          fontSize: "13px",
                          border: "1px solid #ae46fe",
                          marginTop: "",
                          marginRight: "0px",
                          minHeight: "5vh",
                          borderRadius: ".5rem",
                          display: "block",
                          width: "100%",
                        }}
                        onClick={() =>
                          showModel({
                            name,
                            transactionsID,
                            hash,
                            transferPrice,
                            newOwnerAddress,
                            minValue,
                            nftCreator,
                          })
                        }
                      >
                        {" "}
                        Purchase Now
                      </button>
                    </div>
                  </div>
                </div>
              </>
            );
          })}

        <br />

        <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <button
              style={{
                background:
                  visibleDiv === 1
                    ? "linear-gradient(to right, #d201ee, #6e01f7)"
                    : "transparent",
                color: visibleDiv === 1 ? "white" : "#484849",
                border: "none",
                width: visibleDiv === 1 ? "8rem" : "",
                minHeight: "5vh",
                borderRadius: ".5rem",
                fontSize: "15px",
                fontWeight: visibleDiv === 1 ? "600" : "",
                cursor: "pointer",
              }}
              onClick={() => showDiv(1)}
            >
              Description
            </button>
            <button
              style={{
                background:
                  visibleDiv === 2
                    ? "linear-gradient(to right, #d201ee, #6e01f7)"
                    : "transparent",
                color: visibleDiv === 2 ? "white" : "#484849",
                border: "none",
                width: visibleDiv === 2 ? "8rem" : "",
                minHeight: "5vh",
                borderRadius: ".5rem",
                fontSize: "15px",
                fontWeight: visibleDiv === 2 ? "600" : "",
                marginLeft: "auto",
                marginRight: "auto",
                cursor: "pointer",
              }}
              onClick={() => showDiv(2)}
            >
              Offers
            </button>
            <button
              style={{
                background:
                  visibleDiv === 3
                    ? "linear-gradient(to right,  #d201ee, #6e01f7)"
                    : "transparent",
                color: visibleDiv === 3 ? "white" : "#484849",
                border: "none",
                width: visibleDiv === 3 ? "8rem" : "",
                minHeight: "5vh",
                borderRadius: ".5rem",
                fontSize: "15px",
                fontWeight: visibleDiv === 3 ? "600" : "",
                marginLeft: "",
                cursor: "pointer",
              }}
              onClick={() => showDiv(3)}
            >
              Price History
            </button>
          </div>
          <hr style={{ backgroundColor: "#eae7ea" }} />
          <br />
          {visibleDiv === 2 && (
            <div className="tabDiv">
              <div className="row">
                <div className="col-md-6 col-sm-12" style={{ paddingRight: "4%" }}>
                  <div className="row">
                    <div className="col-2 ">
                      <img className="H4vh" src={p221} style={{ height: "6vh" }} />
                    </div>
                    <div className="col-7">
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          marginBottom: "5px",
                        }}
                      >
                        "Hamlet Contemplates ...
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "",
                          marginBottom: "0px",
                        }}
                      >
                        8 hours ago
                      </p>
                    </div>
                    <div className="col-3 text-right">
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          marginBottom: "0px",
                        }}
                      >
                        400 AIFI
                      </p>
                      <p
                        style={{
                          fontSize: "11px",
                          fontWeight: "",
                          marginBottom: "0px",
                        }}
                      >
                        -$12.246
                      </p>
                      <button
                        style={{
                          fontSize: "10px",
                          border: "1px solid #c401f0",
                          borderRadius: ".25rem",
                          minWidth: "4.5rem",
                          minHeight: "2.5vh",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        Place a bid
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-sm-12 M30"
                  style={{ paddingLeft: "4%" }}
                >
                  <div className="row">
                    <div className="col-2">
                      <img className="H4vh" src={p222} style={{ height: "6vh" }} />
                    </div>
                    <div className="col-7">
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          marginBottom: "5px",
                        }}
                      >
                        "Hamlet Contemplates ...
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "",
                          marginBottom: "0px",
                        }}
                      >
                        8 hours ago
                      </p>
                    </div>
                    <div className="col-3 text-right">
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          marginBottom: "0px",
                        }}
                      >
                        400 AIFI
                      </p>
                      <p
                        style={{
                          fontSize: "11px",
                          fontWeight: "",
                          marginBottom: "0px",
                        }}
                      >
                        -$12.246
                      </p>
                      <button
                        style={{
                          fontSize: "10px",
                          border: "1px solid #c401f0",
                          borderRadius: ".25rem",
                          minWidth: "4.5rem",
                          minHeight: "2.5vh",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        Place a bid
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-6 col-sm-12" style={{ paddingRight: "4%" }}>
                  <div className="row ">
                    <div className="col-2 ">
                      <img className="H4vh" src={p223} style={{ height: "6vh" }} />
                    </div>
                    <div className="col-7">
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          marginBottom: "5px",
                        }}
                      >
                        "Hamlet Contemplates ...
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "",
                          marginBottom: "0px",
                        }}
                      >
                        8 hours ago
                      </p>
                    </div>
                    <div className="col-3 text-right">
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          marginBottom: "0px",
                        }}
                      >
                        400 AIFI
                      </p>
                      <p
                        style={{
                          fontSize: "11px",
                          fontWeight: "",
                          marginBottom: "0px",
                        }}
                      >
                        -$12.246
                      </p>
                      <button
                        style={{
                          fontSize: "10px",
                          border: "1px solid #c401f0",
                          borderRadius: ".25rem",
                          minWidth: "4.5rem",
                          minHeight: "2.5vh",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        Place a bid
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-sm-12 M30"
                  style={{ paddingLeft: "4%" }}
                >
                  <div className="row">
                    <div className="col-2">
                      <img className="H4vh" src={p224} style={{ height: "6vh" }} />
                    </div>
                    <div className="col-7">
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          marginBottom: "5px",
                        }}
                      >
                        "Hamlet Contemplates ...
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "",
                          marginBottom: "0px",
                        }}
                      >
                        8 hours ago
                      </p>
                    </div>
                    <div className="col-3 text-right">
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          marginBottom: "0px",
                        }}
                      >
                        400 AIFI
                      </p>
                      <p
                        style={{
                          fontSize: "11px",
                          fontWeight: "",
                          marginBottom: "0px",
                        }}
                      >
                        -$12.246
                      </p>
                      <button
                        style={{
                          fontSize: "10px",
                          border: "1px solid #c401f0",
                          borderRadius: ".25rem",
                          minWidth: "4.5rem",
                          minHeight: "2.5vh",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        Place a bid
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {visibleDiv === 1 && (
            <div className="tabDiv">
              <p style={{ fontSize: "15px", color: "#87818e" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation
              </p>
              <div className="row">
                <div className="col-3">
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    Contact Address
                  </p>
                </div>
                <div className="col-9">
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      marginBottom: "0px",
                    }}
                  >
                    0y48cf...a3c3
                  </p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-3">
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    Token ID
                  </p>
                </div>
                <div className="col-9">
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      marginBottom: "0px",
                    }}
                  >
                    512
                  </p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-3">
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    Token Standard
                  </p>
                </div>
                <div className="col-9">
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      marginBottom: "0px",
                    }}
                  >
                    AIFI-721
                  </p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-3">
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    Blockchain
                  </p>
                </div>
                <div className="col-9">
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      marginBottom: "0px",
                    }}
                  >
                    SharkWeb
                  </p>
                </div>
              </div>
            </div>
          )}

          {visibleDiv === 3 && (
            <div
              className="tabDiv"
              style={{
                backgroundColor: "#fcf9ff",
                padding: "3%",
                borderRadius: ".5rem",
              }}
            >
              <p style={{ fontWeight: "600" }}>All Time Avg. Price</p>
              <img src={graph} style={{ height: "23.5vh", width: "100%" }} />
            </div>
          )}
        </div>
        <br />
        <br />
        <div>
          <span
            style={{
              fontSize: "25px",
              fontWeight: "600",
              marginLeft: "15px",
            }}
          >
            Other NFTs
          </span>
          <a href="#" style={{ cursor: "pointer", float: "right" }}>
            <button
              className="headerGrad"
              style={{
                cursor: "pointer",
                backgroundColor: "#ae46fe ",
                color: "white",
                minWidth: "8rem",
                fontWeight: "600",
                fontSize: "13px",
                border: "1px solid #ae46fe",
                marginLeft: "15px",
                marginRight: "0px",
                minHeight: "5vh",
                marginTop: "",
                marginRight: "10px",
                borderRadius: ".5rem",
              }}
            >
              <span style={{ fontSize: "15px" }}>View More</span>
            </button>
          </a>
          <br /> <br />
          <div className="row m-0 p-0">
            <div className="col-md-6 col-lg-3 col-sm-12">
              <div className="card-purple">
                <img
                  src={s21}
                  style={{ height: "25vh", float: "center", width: "100%" }}
                />
                <br />
                <div style={{ paddingTop: "5%" }}>
                  <span
                    style={{
                      fontSize: "11px",
                      marginBottom: "0px",
                      fontWeight: "700",
                    }}
                  >
                    "Hamlet Contemplates
                  </span>
                  <button
                    className="buds"
                    style={{ float: "right", minHeight: "", fontSize: "" }}
                  >
                    BUSD
                  </button>
                </div>

                <div className="row p-0 ml-0 mr-0 " style={{ marginTop: "3.5%" }}>
                  <div className="col-2 p-0">
                    <img src={s25} style={{ height: "4.2vh" }} />
                  </div>
                  <div className="col-5">
                    <p
                      style={{
                        fontSize: "10px",
                        marginBottom: "0px",
                        color: "grey",
                      }}
                    >
                      Creator
                    </p>

                    <p
                      style={{
                        fontSize: "10px",
                        marginBottom: "0px",
                        fontWeight: "600",
                      }}
                    >
                      John Doe
                    </p>
                  </div>
                  <div className="col-5 text-right">
                    <p
                      style={{
                        fontSize: "10px",
                        marginBottom: "0px",
                        color: "grey",
                      }}
                    >
                      Current Bid
                    </p>

                    <p
                      style={{
                        fontSize: "10px",
                        marginBottom: "0px",
                        fontWeight: "600",
                      }}
                    >
                      200 AIFI
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3  col-sm-12">
              <div className="card-purple MarginT30">
                <img
                  src={s22}
                  style={{ height: "25vh", float: "center", width: "100%" }}
                />
                <br />
                <div style={{ paddingTop: "5%" }}>
                  <span
                    style={{
                      fontSize: "11px",
                      marginBottom: "0px",
                      fontWeight: "700",
                    }}
                  >
                    "Hamlet Contemplates
                  </span>
                  <button
                    className="buds"
                    style={{ float: "right", minHeight: "", fontSize: "" }}
                  >
                    BUSD
                  </button>
                </div>

                <div className="row p-0 ml-0 mr-0 " style={{ marginTop: "3.5%" }}>
                  <div className="col-2 p-0">
                    <img src={s25} style={{ height: "4.2vh" }} />
                  </div>
                  <div className="col-5">
                    <p
                      style={{
                        fontSize: "10px",
                        marginBottom: "0px",
                        color: "grey",
                      }}
                    >
                      Creator
                    </p>

                    <p
                      style={{
                        fontSize: "10px",
                        marginBottom: "0px",
                        fontWeight: "600",
                      }}
                    >
                      John Doe
                    </p>
                  </div>
                  <div className="col-5 text-right">
                    <p
                      style={{
                        fontSize: "10px",
                        marginBottom: "0px",
                        color: "grey",
                      }}
                    >
                      Current Bid
                    </p>

                    <p
                      style={{
                        fontSize: "10px",
                        marginBottom: "0px",
                        fontWeight: "600",
                      }}
                    >
                      200 AIFI
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3  col-sm-12 Mtab20">
              <div className="card-purple MarginT30">
                <img
                  src={s23}
                  style={{ height: "25vh", float: "center", width: "100%" }}
                />
                <br />
                <div style={{ paddingTop: "5%" }}>
                  <span
                    style={{
                      fontSize: "11px",
                      marginBottom: "0px",
                      fontWeight: "700",
                    }}
                  >
                    "Hamlet Contemplates
                  </span>
                  <button
                    className="buds"
                    style={{ float: "right", minHeight: "", fontSize: "" }}
                  >
                    BUSD
                  </button>
                </div>

                <div className="row p-0 ml-0 mr-0 " style={{ marginTop: "3.5%" }}>
                  <div className="col-2 p-0">
                    <img src={s25} style={{ height: "4.2vh" }} />
                  </div>
                  <div className="col-5">
                    <p
                      style={{
                        fontSize: "10px",
                        marginBottom: "0px",
                        color: "grey",
                      }}
                    >
                      Creator
                    </p>

                    <p
                      style={{
                        fontSize: "10px",
                        marginBottom: "0px",
                        fontWeight: "600",
                      }}
                    >
                      John Doe
                    </p>
                  </div>
                  <div className="col-5 text-right">
                    <p
                      style={{
                        fontSize: "10px",
                        marginBottom: "0px",
                        color: "grey",
                      }}
                    >
                      Current Bid
                    </p>

                    <p
                      style={{
                        fontSize: "10px",
                        marginBottom: "0px",
                        fontWeight: "600",
                      }}
                    >
                      200 AIFI
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3  col-sm-12 Mtab20">
              <div className="card-purple MarginT30">
                <img
                  src={s24}
                  style={{ height: "25vh", float: "center", width: "100%" }}
                />
                <br />
                <div style={{ paddingTop: "5%" }}>
                  <span
                    style={{
                      fontSize: "11px",
                      marginBottom: "0px",
                      fontWeight: "700",
                    }}
                  >
                    "Hamlet Contemplates
                  </span>
                  <button
                    className="buds"
                    style={{ float: "right", minHeight: "", fontSize: "" }}
                  >
                    BUSD
                  </button>
                </div>

                <div className="row p-0 ml-0 mr-0 " style={{ marginTop: "3.5%" }}>
                  <div className="col-2 p-0">
                    <img src={s25} style={{ height: "4.2vh" }} />
                  </div>
                  <div className="col-5">
                    <p
                      style={{
                        fontSize: "10px",
                        marginBottom: "0px",
                        color: "grey",
                      }}
                    >
                      Creator
                    </p>

                    <p
                      style={{
                        fontSize: "10px",
                        marginBottom: "0px",
                        fontWeight: "600",
                      }}
                    >
                      John Doe
                    </p>
                  </div>
                  <div className="col-5 text-right">
                    <p
                      style={{
                        fontSize: "10px",
                        marginBottom: "0px",
                        color: "grey",
                      }}
                    >
                      Current Bid
                    </p>

                    <p
                      style={{
                        fontSize: "10px",
                        marginBottom: "0px",
                        fontWeight: "600",
                      }}
                    >
                      200 AIFI
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div
          className="cardJoinCommunity"
          style={{ marginRight: "15px", marginLeft: "15px" }}
        >
          <center>
            <p
              style={{
                fontSize: "35px",
                fontWeight: "700",
                marginBottom: "20px",
              }}
            >
              Create <span className="fontGrad">Your Own NFT!</span>
            </p>
            <p style={{ fontSize: "15px", color: "#a9a5b1" }}>
              We have a large scale group to support each other in this game,
              Join us to get the <br />
              news as soon as possible and follow our latest announcements!
            </p>

            <a href="#" style={{ cursor: "pointer" }}>
              <button
                className="headerGrad"
                style={{
                  cursor: "pointer",
                  backgroundColor: "#ae46fe ",
                  color: "white",
                  minWidth: "12rem",
                  fontWeight: "600",
                  fontSize: "13px",
                  border: "1px solid #ae46fe",
                  marginLeft: "15px",
                  marginRight: "10px",
                  minHeight: "5vh",
                  marginTop: "15px",
                  borderRadius: ".5rem",
                }}
              >
                <span style={{ fontSize: "15px" }}> Join Community Now</span>
              </button>
            </a>
          </center>
        </div>
        <br />
        <br />
      </div>
      <Footer />
      <PurchaseNFT show={show} handleClose={handleClose} nftName={nft} />
    </>
  );
};
export default NftDescription;
