import API from "../service/API";
export const userSignup = async ({
  email,
  password,
  confirmPassword,
  userName,
  wallletAddress,
}) => {
  try {
    const res = await API({
      method: "POST",
      url: "/whiteshark-nft/signup",
      data: { email, password, confirmPassword, userName, wallletAddress },
    });
    return res;
  } catch (error) {
    return error;
  }
};
//==============================user verification
export const userVerification = async ({
  email,
  password,
  userName,
  walletAddress,
}) => {
  try {
    const res = await API({
      method: "POST",
      url: "/whiteshark-nft/verification",
      data: { email, password, userName, walletAddress },
    });

    return res;
  } catch (error) {
    return error;
  }
};
//================================ user signin

export const userSignin = async ({ email, password }) => {
  try {
    const res = await API({
      method: "POST",
      url: "/whiteshark-nft/signin",
      data: { email, password },
    });

    return res;
  } catch (error) {
    return error;
  }
};

//==================================================NFT creation
export const createNFT = async ({ privateKey, passpharse }) => {
  try {
    //=====================================================blockchain api
    if (privateKey && passpharse) {
      const amount = 40;
      const remark = "AIFI";
      const recipient = "swb6cdfb5908a9311ee9827394c3ca9c5b2ipqm";
      const res = await API({
        method: "POST",
        url: "https://testnet.sharkweb.com/transaction/broadcast",
        data: {
          privatekey: privateKey,
          pass: passpharse,
          amount,
          remark,
          recipient,
        },
      });
      return res;
    }
  } catch (error) {
    return error;
  }
};

//=================================================== purchase NFT or ownership transfer

export const purchaseNFT = async ({
  privateKey,
  passpharse,
  newOwnerAddress,
  minValue,
}) => {
  try {
    const remark = "AIFI";
    const recipient = newOwnerAddress;
    // const recipient = "AKJSOIASOAIS09IQ90WSIAISOAISOAIS";
    const res = await API({
      method: "POST",
      url: "https://testnet.sharkweb.com/transaction/broadcast",
      data: {
        privatekey: privateKey,
        pass: passpharse,
        remark,
        recipient,
        amount: minValue,
      },
    });
    return res;
  } catch (error) {}
};
