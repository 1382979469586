const auth = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  ERROR: "ERROR",
  NFT_REQUEST: "NFT_REQUEST",
  NFT_SUCCESS: "NFT_SUCCESS",
  LISTING_NFT_REQUEST: "LISTING_NFT_REQUEST",
  LISTING_NFT_SUCCESS: "LISTING_NFT_SUCCESS",
  OWNERSHIP_REQUEST: "OWNERSHIP_REQUEST",
  OWNERSHIP_SUCCESS: "OWNERSHIP_SUCCESS",
  USER_STATUS: "USER_STATUS",
};
export default auth;
