import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form, Row, Col , Navbar , Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import MobileLogo from '../Images/logo5.png'
import Instagram from '../Images/Instagram.png'
import Twitter from '../Images/Twitter.png'

import discord from '../Images/discord.png'

import teligram from '../Images/telegram.png'
export default class Footer extends Component {

    render() {
        return (
            <>
<div className='Foot'>
                <section className="d-flex justify-content-between p-4 abc" style={{alignItems:'center'}}>
                    <div className="me-5">
                    <span><img className="Footer-Logo" src={MobileLogo} style={{width:'250px'}}/></span>
                    </div>
                    <div className='d-flex abc'>
                        <a href="https://sharkweb.com/" target="_blank" style={{textDecoration:"none",color:" black"}} className="mr-4   me-4">
                        <p>SharkWeb</p>
                        </a>
                        {/* <a href="" className="   me-4">
                        <p>Documentation</p>
                        </a> */}
                        <a href="https://sharkweb.com/files/privacy_policy.pdf" target="_blank" style={{textDecoration:"none",color:" black"}} className="mr-4   me-4">
                        <p>Privacy Policy</p>
                        </a>
                        <a href="https://sharkweb.com/files/terms_of_use.pdf" target="_blank" style={{textDecoration:"none",color:" black"}} className="mr-4   me-4">
                        <p>Terms of Services</p>
                        </a>
                        
                    </div>
                    <div className='d-flex '>
                    <a href="" className="mr-4  me-1" style={{textDecoration:"none",color:" black"}}>
                        <p>Join us :</p>
                        </a>
                        <a href="https://twitter.com/sharkwebchain" target="_blank" className="  ">
                        <img className="MediaHeight" src={Twitter} style={{height:'4vh',float:'',marginRight:'10px',cursor:'pointer'}}/>
                        </a>
                        <a href="https://www.instagram.com/sharkwebchain/" target="_blank" className="   me-2">
                        <img className="MediaHeight" src={Instagram} style={{height:'4vh',float:'',marginRight:'10px',cursor:'pointer'}}/>
                        </a>
                        <a href="https://t.me/sharkwebchat" target="_blank" className="  ">
                        <img className="MediaHeight" src={teligram} style={{height:'4vh',float:'',marginRight:'10px',cursor:'pointer'}}/>
                        </a>
                        <a href="https://discord.gg/VtgkPRef53" target="_blank" className="   me-4">
                        <img className="MediaHeight" src={discord} style={{height:'4vh',float:'',marginRight:'',cursor:'pointer'}}/>
                        </a>
                        </div>
                </section>
            </div>

 

          
            </>
        )
    }
}

